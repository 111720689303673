import { Button, Drawer } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import _ from 'lodash';
import {
  FC, useCallback, useMemo, useState
} from 'react';
import { useBoolean } from 'usehooks-ts';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { CareNote } from '../../../../uc-api-sdk';
import { AnchorComponent } from '../../../../uiComponent/AnchorComponent/AnchorComponent';
import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';
import { CreateCareNoteFormContainer } from '../../container/CreateCareNoteFormContainer/CreateCareNoteFormContainer';
import { CareNoteMapType } from '../../types/types';
import { CareNoteListWithDateComponent } from '../CareNoteListWithDateComponent/CareNoteListWithDateComponent';
import { CareNoteTagFilterComponent } from '../CareNoteTagFilterComponent/CareNoteTagFilterComponent';

export interface CareNotesComponentProps {
  careNotes: CareNote[];
}

export const CareNotesComponent: FC<CareNotesComponentProps> = ({
  careNotes,
}) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);

  const { info } = usePatientContext();
  const timezone = info?.patientInfoService?.getTimezone();
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const reducer = useCallback((a: CareNoteMapType, b: CareNote) => {
    const createdAt = TimezoneService.calcDateTimeDayjs(b.createdAt || '', timezone);
    const year = createdAt.year();
    if (year in a) {
      a[year].push(b);
    } else {
      return { ...a, [year]: [b] };
    }
    return a;
  }, [timezone]);
  const filteredNotes = useMemo(() => (filteredTags.length > 0
    ? careNotes.filter((n) => _.intersection(n.tags, filteredTags).length > 0)
    : careNotes), [filteredTags, careNotes]);

  const noteMap = filteredNotes.reduce(reducer, {});
  const keys = Object.keys(noteMap);
  keys.sort();
  keys.reverse();
  const renderNoteCards = () => keys.map((year) => (
    <CareNoteListWithDateComponent
      notes={noteMap[year]}
      year={Number(year)}
      key={String(year)}
    />
  ));

  const handleChange = (values: CheckboxValueType[]) => {
    setFilteredTags(values as string[]);
  };

  const renderBody = () => {
    if (careNotes.length === 0) {
      return (
        <NoDataWithActionComponent
          description="No care note record at this time."
          actionLinkText="Create a Care Note"
          actionLinkOnClick={setOpen}
        />
      );
    }
    return (
      <AnchorComponent>
        <div className="CareNotesComponent  pr20">
          <div className="careNotes w100">
            <div className="flex jc-e">
              <CareNoteTagFilterComponent
                // values={filteredTags}
                onChange={handleChange}
              />
              <Button type="link" onClick={setOpen}>Add Note</Button>
            </div>
            <div className="careNotes">
              {renderNoteCards()}
            </div>
          </div>
        </div>
      </AnchorComponent>
    );
  };

  return (
    <>
      {renderBody()}
      <Drawer
        open={open}
        onClose={setClose}
        className="header-reversed"
        title="Add Care Note"
        destroyOnClose
      >
        <CreateCareNoteFormContainer
          onSubmit={setClose}
          onCancel={setClose}
        />
      </Drawer>
    </>
  );
};
