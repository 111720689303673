import { AnnouncementModalComponent, AnnouncementModalComponentProps } from '../../component/AnnouncementModalComponent/AnnouncementModalComponent';

export interface AnnouncementModalContainerProps extends Omit<AnnouncementModalComponentProps, 'onCancel'> {
  onCancel?: () => void;
}

export const AnnouncementModalContainer = ({
  onCancel,
  ...props
}: AnnouncementModalContainerProps) => (
  <div className="AnnouncementModalContainer">
    <AnnouncementModalComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onCancel={onCancel}
    />
  </div>
);
