import { Card } from 'antd';
import { difference } from 'lodash';
import { useMemo } from 'react';
import { AddOnServiceEnum, PatientProtocolEnum, ProgramCategoryEnum } from '../../../../uc-api-sdk';
import { EligibleProgramsTooltipComponent } from '../../../../uiComponent/EligibleProgramsTooltipComponent/EligibleProgramsTooltipComponent';
import { VitalsToMonitorTooltipComponent } from '../../../../uiComponent/VitalsToMonitorTooltipComponent/VitalsToMonitorTooltipComponent';

import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { useProgramEnrollmentForm } from '../../hook/useProgramEnrollmentForm/useProgramEnrollmentForm';
import { AddOnServicesCheckboxComponent } from '../AddOnServicesCheckboxComponent/AddOnServicesCheckboxComponent';
import { EnrollmentCareProtocolComponent } from '../EnrollmentCareProtocolComponent.tsx/EnrollmentCareProtocolComponent';
import { acceptedEnrollmentPrograms } from '../EnrollmentProgramsCheckboxComponent/constant';
import { EnrollmentProgramsCheckboxComponent } from '../EnrollmentProgramsCheckboxComponent/EnrollmentProgramsCheckboxComponent';
import { EnrollmentVitalsCheckboxComponent } from '../EnrollmentVitalsCheckboxComponent/EnrollmentVitalsCheckboxComponent';
import './PatientVitalsAndProgramsComponent.scss';

export interface PatientVitalsAndProgramsComponentProps {
  clinicProgramParticipation?: ProgramCategoryEnum[];
  isSaasModel?: boolean;
  isAPCMEligible?: boolean;
  eligiblePrograms: ProgramCategoryEnum[];
  recommendedProtocol?: PatientProtocolEnum;
  addOnServices?: AddOnServiceEnum[];
}

export const PatientVitalsAndProgramsComponent = ({
  clinicProgramParticipation,
  isSaasModel,
  isAPCMEligible,
  eligiblePrograms,
  recommendedProtocol,
  addOnServices,
}: PatientVitalsAndProgramsComponentProps) => {
  const peForm = useProgramEnrollmentForm();
  const isCGMEligible = addOnServices?.includes(AddOnServiceEnum.CGM);

  const disabledPrograms = useMemo(() => {
    let disabledPrograms = [] as ProgramCategoryEnum[];
    if (!isSaasModel) {
      // if SaaS model, enable all accepted programs
      disabledPrograms = difference(acceptedEnrollmentPrograms, eligiblePrograms);
    }
    if (isAPCMEligible) {
      disabledPrograms.filter((p) => p !== ProgramCategoryEnum.APCM);
    } else if (!disabledPrograms.includes(ProgramCategoryEnum.APCM)) {
      disabledPrograms.push(ProgramCategoryEnum.APCM);
    }
    return disabledPrograms;
  }, [isAPCMEligible, isSaasModel, eligiblePrograms]);

  return (
    <div className="patient-enrollment-vitals-and-programs">
      <div className="mb20">
        <TextComponent
          className="mb5"
          size="large"
          bold
        >
          <VitalsToMonitorTooltipComponent>
            Vitals to Monitor
          </VitalsToMonitorTooltipComponent>
        </TextComponent>
        <Card>
          <FormItem info={peForm.getInfo('vitals')} required>
            <EnrollmentVitalsCheckboxComponent
              className="checkbox-group-btn-style w100"
              isCGMEligible={isCGMEligible}
            />
          </FormItem>
        </Card>
      </div>
      {
          isCGMEligible && (
          <div className="mb20">
            <TextComponent
              className="mb5"
              size="large"
              bold
            >
              Additional Service
            </TextComponent>
            <Card>
              <FormItem dependencies={['vitals']}>
                {({ getFieldValue }) => {
                  const selectedVitals = peForm.getValue('vitals', getFieldValue);
                  return (
                    <AddOnServicesCheckboxComponent
                      selectedVitals={selectedVitals}
                    />
                  );
                }}
              </FormItem>
            </Card>
          </div>
          )
        }
      <div className="mb20">
        <EligibleProgramsTooltipComponent>
          <TextComponent
            className="mb5"
            size="large"
            bold
          >
            Billing Programs
          </TextComponent>
        </EligibleProgramsTooltipComponent>
        <Card>
          <FormItem info={peForm.getInfo('programs')} required>
            <EnrollmentProgramsCheckboxComponent
              medicalOrgProgramParticipation={clinicProgramParticipation}
              disabledPrograms={disabledPrograms}
              className="checkbox-group-btn-style programs-checkbox-group w100"
            />
          </FormItem>
        </Card>
      </div>
      <FormItem
        noStyle
        shouldUpdate={peForm.shouldUpdate(['addOnServices'])}
      >
        {
            ({ getFieldValue }) => {
              if (!recommendedProtocol) {
                return null;
              }
              const selectedServices = peForm.getValue('addOnServices', getFieldValue);
              const isCGMSelected = selectedServices?.includes(AddOnServiceEnum.CGM);
              return (
                <EnrollmentCareProtocolComponent
                  recommendedProtocol={recommendedProtocol}
                  hasCGMService={isCGMSelected}
                />
              );
            }
          }
      </FormItem>
    </div>
  );
};
