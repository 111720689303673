import { Empty } from 'antd';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { DeviceListComponent } from '../../component/DeviceListComponent/DeviceListComponent';
import { usePatientGetPatientDevice } from '../../../../uc-api-sdk';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { CGMDeviceItemContainer } from '../../../CGMDevice/container/CGMDeviceItemContainer/CGMDeviceItemContainer';
import { EmptyComponentWrapper } from '../../../../uiComponent/EmptyComponentWrapper/EmptyComponentWrapper';

export interface DeviceTableContainerProps {
  patientId?: string;
}

export const DeviceTableContainer = ({
  patientId,
}: DeviceTableContainerProps) => {
  const deviceInfo = usePatientGetPatientDevice({
    params: {
      memberId: patientId ?? '',
    },
  });

  useUpdateListener('DEVICE_MONITOR_UPDATED', deviceInfo.refetch);

  return (
    <FetchComponent
      info={deviceInfo}
      alwaysShowChildren
      showErrorOverlay
      showLoadingOverlay
    >
      <div className="device-list">
        <EmptyComponentWrapper
          emptyDisplay={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        >
          <CGMDeviceItemContainer
            patientId={patientId || ''}
          />
          {
            deviceInfo.data?.data?.deviceList ? (
              <DeviceListComponent
                patientId={patientId || ''}
                patientDevice={deviceInfo.data?.data}
              />
            ) : null
          }
        </EmptyComponentWrapper>
      </div>
    </FetchComponent>
  );
};
