import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { InterventionVitalsMonitoringComponent } from '../../component/InterventionVitalsMonitoringComponent/InterventionVitalsMonitoringComponent';
import { InterventionContainerProps } from '../../type';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { VitalsMonitoringScheduleFormContainer } from '../../../vitals/container/VitalsMonitoringScheduleFormContainer/VitalsMonitoringScheduleFormContainer';
import { usePatientGetEnrolledPrograms } from '../../../../uc-api-sdk';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { InterventionSubmitCancelButtons } from '../../component/InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface InterventionVitalsMonitoringContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps { }

export const InterventionVitalsMonitoringContainer = ({
  patientId,
  visitId,
  showEdit,
  onEdit,
  isEditing,
  onSubmit,
  onError,
  onCancel,
  onValuesChange,
  formButtons = (<InterventionSubmitCancelButtons onCancel={onCancel} />),
}: InterventionVitalsMonitoringContainerProps) => {
  const { info } = usePatientContext();

  const enrolledProgramInfo = usePatientGetEnrolledPrograms({
    params: { memberId: patientId },
  });

  const debouncedEnrolledProgramRefetch = useDebounce(
    enrolledProgramInfo.refetch,
    250,
    [enrolledProgramInfo.refetch]
  );

  useUpdateListener('WORKLIST_UPDATED', debouncedEnrolledProgramRefetch);
  useUpdateListener('PATIENT_INFO_UPDATED', debouncedEnrolledProgramRefetch);

  const { send } = useBillableTimeInterventionComp();

  const handleOnEdit = () => {
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.UpdateCarePlan,
      detail: BillableTimeInterventionDetailEnum.InterventionVitalsMonitoringGoalEditIcon,
    });
    onEdit?.();
  };

  const handleOnSubmit = () => {
    debouncedEnrolledProgramRefetch();
    info?.refetch?.(['enrolledProgramRefetch']);
    onSubmit?.();
  };

  const enrolledProgramData = enrolledProgramInfo.data?.data;
  const vitals = enrolledProgramData?.vitals || [];
  const isLoading = enrolledProgramData === undefined || enrolledProgramInfo.isLoading;

  return (
    <PatientCommonCardComponent
      title="Vitals Monitoring Goal"
      showHeaderOutside={false}
      showEdit={showEdit}
      isEditing={isEditing}
      onEdit={handleOnEdit}
      updateInfo={enrolledProgramData}
      content={(
        <LoadingOverlayComponent
          isLoading={isLoading}
          showSkeleton
        >
          <InterventionVitalsMonitoringComponent
            vitals={vitals}
          />
        </LoadingOverlayComponent>
      )}
      formContent={(
        <LoadingOverlayComponent
          isLoading={isLoading}
          showSkeleton
        >
          <VitalsMonitoringScheduleFormContainer
            patientId={patientId}
            visitId={visitId}
            vitals={vitals}
            onSubmit={handleOnSubmit}
            onCancel={onCancel}
            allowRemoveVital={false}
            onError={onError}
            onValuesChange={onValuesChange}
            formButtons={formButtons}
          />
        </LoadingOverlayComponent>
      )}
    />
  );
};
