import { useInterval } from 'usehooks-ts';
import { partial } from 'lodash';
import { Button, message } from 'antd';
import { useState } from 'react';
import { Icons } from '../../../../icons/Icons';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  CgmBrandEnum,
  CgmModelEnum,
  useCgmFetchLinkSession, useCgmSendAuthMessage
} from '../../../../uc-api-sdk';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

import './CGMDeviceLinkAccountButtonContainer.scss';

const REFRESH_TIME = 5000; // ms

enum LinkAccountMethod {
  CARE_PORTAL = 'care_portal',
  SMS = 'sms',
}
export interface CGMDeviceLinkAccountButtonContainerProps {
  patientId: string;
  brand: CgmBrandEnum;
  model: CgmModelEnum;
  disabled?: boolean;
  onClick?: (method: LinkAccountMethod, isSent: boolean) => void;
}

export const CGMDeviceLinkAccountButtonContainer = ({
  patientId,
  brand,
  model,
  disabled,
  onClick,
}: CGMDeviceLinkAccountButtonContainerProps) => {
  const [selectedMethod, setSelectedMethod] = useState<LinkAccountMethod | undefined>();
  const [isRequestSent, setIsRequestSent] = useState(false);
  const portalLinkInfo = useCgmFetchLinkSession({});
  const sendLinkInfo = useCgmSendAuthMessage({});

  const updatePatientCGMDevices = useUpdate('CGM_LINKING_UPDATED').updateValue;

  useInterval(() => {
    updatePatientCGMDevices();
  }, isRequestSent ? REFRESH_TIME : null);

  const isLoading = portalLinkInfo.isLoading || sendLinkInfo.isLoading;
  const isDisabled = disabled || isLoading;

  const getErrorMessage = () => (
    !model ? 'Please select a model' : ''
  );

  const handleOnLinkAccountClick = async (
    method: LinkAccountMethod
  ) => {
    setSelectedMethod(method);
    let res;
    let isSent = false;
    const request = {
      ucPatientId: patientId,
      brands: [brand],
      model,
    };
    if (method === LinkAccountMethod.SMS) {
      res = await ApiRequestHelper.tryCatch(sendLinkInfo.send({
        params: { request }
      }), { success: '', error: getErrorMessage() });

      if (res?.code === 200) {
        isSent = true;
        message.success('Linking Request is sent to patient!');
      }
    }

    if (method === LinkAccountMethod.CARE_PORTAL) {
      res = await ApiRequestHelper.tryCatch(portalLinkInfo.send({
        params: { request }
      }), { success: '', error: getErrorMessage() });

      const url = res?.data?.url;
      if (url) {
        isSent = true;
        window.open(url, '_blank');
      }
    }
    if (isSent) {
      setIsRequestSent(true);
    }
    updatePatientCGMDevices();
    onClick?.(method, isSent);
    setSelectedMethod(undefined);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={partial(handleOnLinkAccountClick, LinkAccountMethod.SMS)}
        disabled={isDisabled}
        loading={selectedMethod === LinkAccountMethod.SMS && isLoading}
      >
        Send Linking Request via SMS
      </Button>
      <span className="mx10">
        Or
      </span>
      <Button
        type="default"
        onClick={partial(handleOnLinkAccountClick, LinkAccountMethod.CARE_PORTAL)}
        className="link-account-button"
        disabled={isDisabled}
        loading={selectedMethod === LinkAccountMethod.CARE_PORTAL && isLoading}
      >
        Link Account via Care Portal
        {' '}
        <Icons.DiagonalArrowIcon className="ml5" asSVG />
      </Button>
    </>
  );
};
