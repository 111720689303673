import { DatePicker, Divider, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { forEach, omit } from 'lodash';
import { InputTypeRequired } from '../../features/Input/types';
import { FromDateToDateDayjs } from '../../types/date';
import { USA_DATE } from '../../constants/timeFormat';
import DatePeriodDropdownComponent, { DatePeriodDropdownComponentProps, DatePeriodEnum } from '../DateDropdownAndRangeComponent/DatePeriodDropdownComponent';
import { PrevNextComponent } from '../PrevNextComponent/PrevNextComponent';
import './DateRangeSelectComponent.scss';

export interface DateRangeSelectComponentProps extends
  InputTypeRequired<FromDateToDateDayjs> {
  styleType?: 'full' | 'short';
  dropdownOptions?: DatePeriodDropdownComponentProps['dropdownOptions'];
  open?: boolean;
  onOpenChange?: RangePickerProps['onOpenChange'];
  dateRender?: RangePickerProps['dateRender']
  popupClassName?: string;
  renderExtraFooter?: RangePickerProps['renderExtraFooter'];
}

export const DateRangeSelectComponent = ({
  value,
  onChange,
  styleType = 'full',
  dropdownOptions,
  open,
  onOpenChange,
  dateRender,
  popupClassName,
  renderExtraFooter,
}: DateRangeSelectComponentProps) => {
  const unit = 'days';
  const handleChange: RangePickerProps['onChange'] = (v) => {
    onChange?.({
      fromDate: dayjs(v?.[0]?.toDate()).startOf(unit),
      toDate: dayjs(v?.[1]?.toDate()).endOf(unit),
    });
  };
  const handleDatePeriodChange: DatePeriodDropdownComponentProps['onChange'] = (v) => {
    const date = value?.toDate || dayjs();
    onChange?.({
      fromDate: date.clone().startOf(unit).add(-(v || 0) + 1, unit),
      toDate: date.clone().endOf(unit),
    });
  };

  const getDatePeriodValue = () => {
    if (value?.fromDate && value?.toDate) {
      return value.toDate.diff(value.fromDate.add(-1, unit), unit);
    }
    return 1;
  };

  const handleGoNextOrPrev = (direction: 1 | -1) => () => {
    const diff = getDatePeriodValue();
    onChange?.({
      fromDate: value.fromDate.clone().startOf(unit).add(direction * diff, unit),
      toDate: value.toDate.clone().endOf(unit).add(direction * diff, unit),
    });
  };

  const getRangePickerRanges = (): Record<string, [moment.Moment, moment.Moment]> => {
    let defaultRanges = {
      'Last 7 Days': [moment().startOf('day').add(-6, unit), moment()],
      'Last 14 Days': [moment().startOf('day').add(-13, unit), moment()],
      'Last 30 Days': [moment().startOf('day').add(-29, unit), moment()],
      'Last 90 Days': [moment().startOf('day').add(-89, unit), moment()],
    } as Record<string, [moment.Moment, moment.Moment]>;

    const dropdownMap = {
      'Last 7 Days': DatePeriodEnum.SevenDays,
      'Last 14 Days': DatePeriodEnum.FourteenDays,
      'Last 30 Days': DatePeriodEnum.ThirtyDays,
      'Last 90 Days': DatePeriodEnum.NinetyDays,
    } as Record<string, DatePeriodEnum>;

    if (!dropdownOptions) {
      return defaultRanges;
    }
    forEach(defaultRanges, (v, key) => {
      const period = dropdownMap[key];
      if (!dropdownOptions.includes(period)) {
        defaultRanges = omit(defaultRanges, key);
      }
    });
    return defaultRanges;
  };

  const renderRangePicker = () => (
    <DatePicker.RangePicker
      open={open}
      value={[
        moment(dayjs(value?.fromDate).toDate()).startOf(unit),
        moment(dayjs(value?.toDate).toDate()).endOf(unit),
      ]}
      format={USA_DATE}
      onChange={handleChange}
      allowClear={false}
      separator={<Divider type="vertical" className="range-divider" />}
      ranges={getRangePickerRanges()}
      dateRender={dateRender}
      onOpenChange={onOpenChange}
      popupClassName={popupClassName}
      renderExtraFooter={renderExtraFooter}
    />
  );

  return (
    styleType === 'full'
      ? (
        <div className="date-range-select-component">
          <Space>
            <PrevNextComponent
              gotoNext={handleGoNextOrPrev(1)}
              gotoPre={handleGoNextOrPrev(-1)}
            >
              {renderRangePicker()}
            </PrevNextComponent>
            <div>
              <DatePeriodDropdownComponent
                value={getDatePeriodValue()}
                onChange={handleDatePeriodChange}
                dropdownOptions={dropdownOptions}
              />
            </div>
          </Space>
        </div>
      )
      : renderRangePicker()
  );
};
