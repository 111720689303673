import { Button } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimeType } from '../../types/common';
import { DisplayDateComponent, DisplayDateComponentProps } from '../DisplayComponent/DisplayDateComponent';

import './DateNavigatorComponent.scss';

type NavigationType = 'prev' | 'next';

const getDayjsFunctionByType = (type: NavigationType) => (
  type === 'prev' ? 'subtract' : 'add'
);

type DateFormatType = [
  DisplayDateComponentProps['format'],
  DisplayDateComponentProps['format']
];

type DateFormatCallback = (
  startDate: Dayjs | undefined,
  endDate: Dayjs | undefined
) => DateFormatType;

export interface DateNavigatorComponentProps {
  endDate?: DateTimeType;
  onChange?: (endDate: Dayjs, startDate?: Dayjs) => void;
  startDateRange?: DateTimeType;
  endDateRange?: DateTimeType;
  rangeCap?: boolean; // to prevent the new dates from going beyond range
  period?: number;
  prevButton?: ReactNode;
  nextButton?: ReactNode;
  dateFormat?: DateFormatType | DateFormatCallback;
  oneDayDisplay?: boolean; // if the range is only for one day, display only one
}

export const DateNavigatorComponent = ({
  endDate,
  onChange,
  startDateRange,
  endDateRange,
  rangeCap = true,
  period = 1, // always by days
  prevButton = 'Prev',
  nextButton = 'Next',
  dateFormat = ['USA_DATE', 'USA_DATE'],
  oneDayDisplay = true,
}: DateNavigatorComponentProps) => {
  const [
    selectedDate,
    setSelectedDate,
  ] = useState<Dayjs | undefined>(dayjs(endDate));

  const getStartDate = (
    endDate?: Dayjs
  ) => {
    let newStartDate: Dayjs | undefined;
    if (period > 1 && endDate) {
      newStartDate = endDate.subtract(period - 1, 'day').startOf('day');
    }
    return newStartDate;
  };

  const [startDateFormat, endDateFormat] = (
    typeof dateFormat === 'function'
      ? dateFormat(selectedDate, getStartDate(selectedDate))
      : dateFormat
  );

  const shouldDisable = (type: NavigationType) => {
    const currentEndDate = dayjs(selectedDate).endOf('day');
    if (type === 'prev' && startDateRange) {
      const currentStartDate = getStartDate(currentEndDate);
      const startDateRangeDayjs = dayjs(startDateRange).startOf('day');
      return (
        currentEndDate.isBefore(startDateRangeDayjs)
        || currentStartDate?.isBefore(startDateRangeDayjs)
        || currentStartDate?.isSame(startDateRangeDayjs)
      );
    }
    if (type === 'next' && endDateRange) {
      const endDateRangeDayjs = dayjs(endDateRange).endOf('day');
      return (
        currentEndDate.isAfter(endDateRangeDayjs)
        || currentEndDate.isSame(endDateRangeDayjs)
      );
    }
    return false;
  };

  const capRange = (
    newStartDate?: Dayjs,
    newEndDate?: Dayjs
  ) => {
    let cappedStartDate = newStartDate?.clone();
    let cappedEndDate = newEndDate?.clone();
    if (rangeCap) {
      const endDateRangeDayjs = dayjs(endDateRange).endOf('day');
      if (newEndDate?.isAfter(endDateRangeDayjs)) {
        cappedEndDate = endDateRangeDayjs;
      }
      const startDateRangeDayjs = dayjs(startDateRange).startOf('day');
      if (newStartDate?.isBefore(startDateRangeDayjs)) {
        cappedStartDate = startDateRangeDayjs;
      }
    }
    return [cappedEndDate, cappedStartDate];
  };

  const updateStartAndEndDate = (
    endDate?: DateTimeType
  ) => {
    const endDateDayjs = dayjs(endDate).endOf('day');
    const startDateDayjs = getStartDate(endDateDayjs);
    const [
      newEndDate,
      newStartDate
    ] = capRange(startDateDayjs, endDateDayjs) as [Dayjs, Dayjs];
    setSelectedDate(newEndDate);
    onChange?.(newEndDate, newStartDate);
  };

  const navigate = (type: 'prev' | 'next') => {
    const fn = getDayjsFunctionByType(type);
    const end = dayjs(selectedDate).endOf('day');
    const newEndDate = end[fn](period, 'day');
    updateStartAndEndDate(newEndDate);
  };

  const renderDateDisplay = () => {
    const isPeriodGT1 = period > 1;
    const [endDate, startDate] = capRange(getStartDate(selectedDate), selectedDate);
    const isOneDay = startDate?.isSame(endDate, 'day');

    if (isOneDay && oneDayDisplay) {
      return (
        <DisplayDateComponent
          value={endDate}
          format={startDateFormat}
        />
      );
    }

    return (
      <div className="flex ai-c jc-c">
        {
          isPeriodGT1
          && (
            <DisplayDateComponent
              value={startDate}
              format={startDateFormat}
            />
          )
        }
        {
          isPeriodGT1 && ' - '
        }
        <DisplayDateComponent
          value={endDate}
          format={endDateFormat}
        />
      </div>
    );
  };

  useEffect(() => {
    updateStartAndEndDate(endDate);
  }, [endDate]);

  return (
    <div
      className="date-navigator"
    >
      <Button
        className="date-navigator__prev"
        disabled={shouldDisable('prev')}
        onClick={() => navigate('prev')}
      >
        {prevButton}
      </Button>
      <div
        className="date-navigator__display minW80 ta-c"
      >
        {renderDateDisplay()}
      </div>
      <Button
        className="date-navigator__next"
        disabled={shouldDisable('next')}
        onClick={() => navigate('next')}
      >
        {nextButton}
      </Button>
    </div>
  );
};
