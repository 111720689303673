import dayjs from 'dayjs';
import { compact, map } from 'lodash';
import { DateTimeType } from '../../types/common';

interface Ranges<T> {
  [key: string]: {
    color?: string;
    value: T;
  };
}

interface RangeOptions {
  axisName?: string;
  color?: string;
  width?: number;
}

interface TimeAxisOptions
  extends Omit<Highcharts.XAxisOptions, 'labels' | 'plotBands' | 'scrollbar'>,
  Omit<Highcharts.YAxisOptions, 'labels' | 'plotBands' | 'scrollbar'> {
  labels?: Highcharts.XAxisLabelsOptions | Highcharts.YAxisLabelsOptions;
  date?: DateTimeType;
  formatter?: (data: Highcharts.AxisLabelsFormatterContextObject) => string;
}

export const useAxis = () => {
  const makeRanges = <T>(
    ranges: Ranges<T>,
    options: RangeOptions = {}
  ) => {
    const plotLines = map(ranges, (range) => {
      if (range.color) {
        return {
          ...range,
          ...options,
          color: range.color || options.color,
          width: options.width || 1,
        };
      }
      return null;
    });
    return {
      plotLines: compact(plotLines),
    };
  };

  const makeTimeAxis = ({ date, formatter, ...options }: TimeAxisOptions) => ({
    type: 'datetime',
    tickPositioner: () => {
      const start = dayjs(date).startOf('day').valueOf();
      const end = dayjs(date).endOf('day').valueOf() + 1; // 12:00 AM of the next day
      const interval = 3 * 60 * 60 * 1000; // big tick every 3 hours
      const ticks = [];
      for (let i = start; i <= end; i += interval) {
        ticks.push(i);
      }
      return ticks;
    },
    minorTickInterval: 1 * 60 * 60 * 1000, // small tick every hour
    minorTicks: true,
    minorTickWidth: 1,
    minorTickLength: 3,
    minorGridLineWidth: 0,
    startOnTick: true,
    endOnTick: true,
    showLastLabel: true,
    labels: {
      formatter() {
        const data = this as unknown as Highcharts.AxisLabelsFormatterContextObject;
        if (formatter) return formatter(data);
        return dayjs(data?.value).format('hh:mm A');
      },
      align: 'center',
    },
    ...options,
  } as Highcharts.XAxisOptions);

  return {
    makeRanges,
    makeTimeAxis,
  };
};
