import { Badge, Button, notification } from 'antd';
import { useCallback, useEffect } from 'react';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { Icons } from '../../../../icons/Icons';
import { AnnouncementUserFeed, useAnnouncementReadAnnouncement } from '../../../../uc-api-sdk';
import { AnnouncementListComponent } from '../AnnouncementListComponent/AnnouncementListComponent';
import './AnnouncementButtonComponent.scss';

export interface AnnouncementButtonComponentProps {
  announcements?: AnnouncementUserFeed[]
  onLoadMore?: () => void
}

export const AnnouncementButtonComponent = ({
  announcements,
  onLoadMore
}: AnnouncementButtonComponentProps) => {
  const readAnnouncement = useAnnouncementReadAnnouncement({});
  const refetch = useUpdate('ANNOUNCEMENT_UPDATED');

  const { isOpen, open, close } = useOpen();

  const handleRead = useCallback((announcementId: string) => {
    const req = readAnnouncement.send({
      params: {
        id: announcementId,
      },
    });

    ApiRequestHelper.tryCatch(req, {
      success: '',
      error: '',
      onSuccess: () => {
        refetch.updateValue();
      },
    });
  }, [readAnnouncement]);

  const key = 'announcement-notification';

  const openNotification = () => {
    open();
    notification.open({
      message: <div className="announcement-notification-title b6">Updates</div>,
      duration: 0,
      key,
      className: 'announcement-notification-container mt50',
      maxCount: 1,
      description: (
        <AnnouncementListComponent
          announcements={announcements}
          onLoadMore={onLoadMore}
          onRead={handleRead}
        />
      ),
      onClose: close
    });
  };

  useEffect(() => {
    if (isOpen) {
      openNotification();
    }
  }, [announcements]);

  const hasUnread = announcements?.some((announcement) => announcement.userStatus !== 'READ');

  return (
    <Badge
      offset={[-7, 7]}
      dot={hasUnread}
      className="announcement-button-badge"
    >
      <Button
        type="text"
        className="p0 m0"
        onClick={openNotification}
      >
        <Icons.RingingBellIcon />
      </Button>
    </Badge>
  );
};
