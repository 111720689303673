import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { map } from 'lodash';
import { useCallback } from 'react';
import { MeasureUnitHelper } from '../../../helpers/measurement/measureUnit';
import { CgmDataPoint } from '../../../uc-api-sdk';
import { CGMChartData } from '../container/CGMVitalContainer/type';

dayjs.extend(isSameOrBefore);

export const useCGMChartHelper = () => {
  const renderLabelWithUnit = useCallback((
    value?: string | number,
    unit = MeasureUnitHelper.defaultBGUnit,
  ) => (`
    <div
      class="flex fd-c gap0 ai-c"
      style="position: absolute; left: -26px; top: -7px;"
    >
      <span>${value}</span>
      ${unit}
    </div>
  `), []);

  const xAxisLabelFormatter = useCallback((value?: string | number) => {
    if (!value) return '--';
    const time = dayjs(value).format('hA');
    switch (time) {
      case '12AM':
      case '12PM':
        return `<span style="color: #000; font-weight: 600">${time}</span>`;
      default:
        return time;
    }
  }, []);

  // to convert x to the time of day
  // save original datetime in custom
  const normalizeData = useCallback((data: CgmDataPoint[]): CGMChartData[] => (
    map(data, (d) => {
      const { localTime, value } = d || {};
      const x = dayjs(localTime);
      return {
        x: x?.valueOf() ?? undefined,
        y: value ?? undefined,
        custom: d,
      };
    })
  ), []);

  const convertCGMDataToPoints = useCallback((
    data: CgmDataPoint[],
    showMarker = true,
  ) => {
    const normalizedData = normalizeData(data);
    const lastDataPointIndex = normalizedData.length - 1;
    const lastDataPoint = normalizedData[lastDataPointIndex];
    if (
      showMarker
      && lastDataPoint?.x
      && dayjs(lastDataPoint.x).isSame(dayjs().endOf('day'), 'day')
    ) {
      normalizedData[lastDataPointIndex] = {
        ...normalizedData[lastDataPointIndex],
        // marker: { enabled: true, className: 'now-marker' },
      } as CGMChartData;
    }
    return normalizedData;
  }, []);

  const addSpaceFromLastValue = (value: number, addedValue = 50) => {
    const factor = Math.ceil(value / addedValue);
    return factor * addedValue;
  };

  return {
    renderLabelWithUnit,
    xAxisLabelFormatter,
    convertCGMDataToPoints,
    addSpaceFromLastValue,
  };
};
