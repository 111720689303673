import { createContext, ReactNode, useContext } from 'react';
import { last } from 'lodash';
import {
  APIResponse,
  CgmBrandEnum,
  CgmSupportedDevice,
  PatientCgmDeviceDto,
  useCgmGetCgmSupportedDevices,
  useCgmGetPatientCgmDevice,
  usePatientGetPatientCgmDevice
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';

export interface CGMDeviceContextValue {
  patientCGMDeviceLinkStatusInfo: ReturnType<typeof useCgmGetPatientCgmDevice>;
  patientCGMDeviceLinkingStatus?: PatientCgmDeviceDto;
  getPatientCGMDeviceLinkStatusByBrand: (brand: CgmBrandEnum) => (
    Promise<APIResponse<PatientCgmDeviceDto[]> | null | undefined>
  );
  patientCGMDeviceInfo: ReturnType<typeof usePatientGetPatientCgmDevice>;
}

const CGMDeviceContext = createContext<
  CGMDeviceContextValue | undefined
>(undefined);

export const useCGMDeviceContext = () => {
  const context = useContext(CGMDeviceContext);
  return context as CGMDeviceContextValue;
};

interface CGMDeviceChildrenProps extends CGMDeviceContextValue {
  cgmDeviceData: CgmSupportedDevice[];
}

export interface CGMDeviceContainerProps {
  children: (props: CGMDeviceChildrenProps) => ReactNode;
  patientId?: string;
  getPatientCGMDevice?: boolean;
}

export const CGMDeviceContainer = ({
  children,
  patientId = '',
  getPatientCGMDevice,
}: CGMDeviceContainerProps) => {
  const cgmDeviceDataInfo = useCgmGetCgmSupportedDevices({
    options: { cache: true }
  });
  const patientCGMDeviceLinkStatusInfo = useCgmGetPatientCgmDevice({
    options: { sendOnMount: false },
  });
  const patientCGMDeviceInfo = usePatientGetPatientCgmDevice({
    params: { memberId: patientId },
    options: { sendOnMount: !!patientId && getPatientCGMDevice },
  });

  const patientCGMDeviceLinkingStatus = last(patientCGMDeviceLinkStatusInfo?.data?.data);

  const getPatientCGMDeviceLinkStatusByBrand = (brand: CgmBrandEnum) => (
    patientCGMDeviceLinkStatusInfo.send({
      params: { patientId, request: { brandIn: [brand] } },
    })
  );

  const value = useGetContextValue<CGMDeviceContextValue>({
    patientCGMDeviceLinkStatusInfo,
    patientCGMDeviceLinkingStatus,
    getPatientCGMDeviceLinkStatusByBrand,
    patientCGMDeviceInfo,
  });

  return (
    <FetchComponent
      info={cgmDeviceDataInfo}
      alwaysShowChildren
    >
      {
        (info) => {
          const cgmDeviceData = info?.rawValue?.data || [];
          return (
            <CGMDeviceContext.Provider value={value}>
              {
                children({
                  cgmDeviceData,
                  ...value,
                })
              }
            </CGMDeviceContext.Provider>
          );
        }
      }
    </FetchComponent>
  );
};
