import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { XAxisPlotLinesOptions } from 'highcharts';
import {
  filter,
  first,
  groupBy,
  forEach,
  chunk,
  last,
  compact,
} from 'lodash';
import { useCallback, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { USA_DATE, dddMMMDD } from '../../../../constants/timeFormat';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { CgmDataPoint, CgmPatientContext } from '../../../../uc-api-sdk';
import { CGM_DAYS_LIMIT, CGMPlotLineColor } from '../../constant/cgmConstant';
import { useAxis } from '../../../../hooks/highcharts/useAxis';
import { useCGMChartHelper } from '../../helper/useCGMChartHelper';
import { useNoData } from '../../../../hooks/highcharts/useNoData';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { CGMChartComponentProps, CGMChartComponent } from './CGMChartComponent';

import './CGMChartMainComponent.scss';
import { CGMChartData } from '../../container/CGMVitalContainer/type';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const getCommonXAxis = (): Highcharts.XAxisOptions => ({
  title: { text: '' },
  type: 'datetime',
});

const getClassNameForDate = (date: Dayjs) => date.format('[date-band]-MMDDYYYY');

const bandSelectedClassName = 'cgm-chart-band__selected';

export interface CGMChartMainComponentProps {
  data?: CgmDataPoint[] | null;
  availableDays?: number;
  cgmPatientContext?: CgmPatientContext;
  startDate?: Dayjs;
  endDate?: Dayjs;
  allowStartFromRight?: boolean;
  showAsCompact?: boolean;
  isLoading?: boolean;
}

export const CGMChartMainComponent = ({
  data,
  availableDays,
  cgmPatientContext,
  startDate,
  endDate,
  allowStartFromRight = true,
  showAsCompact,
  isLoading,
}: CGMChartMainComponentProps) => {
  const [chartDetailView, setChartDetailView] = useState<{
    chart: string;
    date: string;
  } | undefined>();
  const axisHelper = useAxis();
  const { makeNoDataPlotBand } = useNoData();
  const {
    convertCGMDataToPoints,
    xAxisLabelFormatter
  } = useCGMChartHelper();

  const daysWithData = useDeepCompareMemo(() => {
    if (typeof availableDays === 'number') {
      return availableDays;
    }
    const groupedByDate = groupBy(data, (d) => dayjs(d?.localTime)?.format(USA_DATE));
    return Object.keys(groupedByDate).length;
  }, [data, availableDays]);
  const noCGMData = daysWithData === 0;
  const isDayView = startDate?.isSame(endDate, 'day');

  const removeSelectedPlotBand = () => {
    const lastSelectedPlotBand = document.querySelector(`.${bandSelectedClassName}`);
    lastSelectedPlotBand?.classList.remove(bandSelectedClassName);
  };

  const closeChartDetailView = () => {
    removeSelectedPlotBand();
    setChartDetailView(undefined);
  };

  const handleOnChartClick = (chartName: string): CGMChartComponentProps['onChartClick'] => (
    data,
    chart
  ) => {
    if (isDayView || noCGMData) {
      return;
    }
    const date = dayjs(data.x);
    setChartDetailView({
      chart: chartName,
      date: date.format(USA_DATE)
    });

    const xAxis = chart.xAxis[0];
    // clean last selection
    removeSelectedPlotBand();
    // highlight chart detail selection
    const selectedPlotBand = document.querySelector(`.${getClassNameForDate(date)}`);
    selectedPlotBand?.classList.add(bandSelectedClassName);
    const startRange = date.startOf('day').valueOf();
    const endRange = date.endOf('day').valueOf() + 1;
    forEach(xAxis.ticks, ({ pos, label }) => {
      if (pos >= startRange && pos <= endRange) {
        label?.addClass('cgm-chart-selection__tick');
      } else {
        label?.removeClass('cgm-chart-selection__tick');
      }
    });
  };

  const getTimeXAxis = useCallback((date?: string) => (
    axisHelper.makeTimeAxis({
      date,
      formatter(data) {
        return xAxisLabelFormatter(data.value);
      },
      min: dayjs(date).startOf('day').valueOf(),
      max: dayjs(date).endOf('day').valueOf(),
      plotLines: [
        {
          value: dayjs(date).startOf('day').add(12, 'hour').valueOf(),
          color: CGMPlotLineColor.LIGHT_GRAY,
          width: 1,
          zIndex: 4,
        },
      ],
      gridLineWidth: 0,
      gridLineColor: 'transparent',
    })
  ), []);
  const getOneDayOptions = useCallback((selectedDate?: string) => {
    const selectedDataView = filter(
      data,
      (d) => dayjs(d?.localTime)?.format(USA_DATE) === selectedDate
    ) as CgmDataPoint[];
    const series = [{
      name: selectedDate || 'oneDaySeries',
      data: convertCGMDataToPoints(selectedDataView),
    }];
    const xAxis = {
      ...getCommonXAxis(),
      ...getTimeXAxis(selectedDate),
    } as Highcharts.XAxisOptions;
    return {
      series,
      xAxis,
    } as Highcharts.Options;
  }, [data]);

  const options = useDeepCompareMemo(() => {
    const options = [] as Highcharts.Options[];
    if (!data || isLoading) {
      return undefined;
    }

    if (noCGMData) {
      const timeXAxis = getTimeXAxis();
      const noDataPlotBand = makeNoDataPlotBand({
        to: timeXAxis.max || 0,
        label: { style: { fontSize: '14px' } }
      });
      const options = {
        series: undefined,
        xAxis: {
          ...getCommonXAxis(),
          ...timeXAxis,
          plotBands: [noDataPlotBand]
        },
      } as Highcharts.Options;
      return [options];
    }

    if (isDayView) {
      const dataDate = dayjs(first(data)?.localTime).format(USA_DATE);
      const oneDayOptions = getOneDayOptions(dataDate);
      return [oneDayOptions];
    }

    const seriesData = convertCGMDataToPoints(data, false);
    const groupedByDate = groupBy(seriesData, (d) => dayjs(d.x).format(USA_DATE));
    const totalDays = Object.keys(groupedByDate).length;
    const startDateDayjs = dayjs(startDate);
    const endDateDayjs = dayjs(endDate);
    // when range is more than 1 day,
    // always show 7 - day x 2 charts(empty placeholders if applicable)
    let dateOutline = [] as string[];
    if (
      allowStartFromRight
      && startDateDayjs.isSame(dayjs(startDate), 'day')
      && totalDays < CGM_DAYS_LIMIT
    ) {
      // not starting from top left
      dateOutline = Array(CGM_DAYS_LIMIT).fill(1).map((_, index) => (
        endDateDayjs.subtract(index, 'day').format(USA_DATE)
      )).reverse();
    } else {
      // starting from top left
      dateOutline = Array(CGM_DAYS_LIMIT).fill(1).map((_, index) => (
        startDateDayjs.add(index, 'day').format(USA_DATE)
      ));
    }
    forEach(chunk(dateOutline, 7), (chunked) => {
      const flatData = chunked.map((date) => groupedByDate[date]).flat();
      const firstDay = dayjs(first(chunked)).startOf('day');
      const lastDay = dayjs(last(chunked)).endOf('day');
      const plotLines = [] as XAxisPlotLinesOptions[];
      const plotBands = [] as Highcharts.XAxisPlotBandsOptions[];
      const ticks = [] as number[];
      let start = firstDay.valueOf();
      while (start <= lastDay.valueOf()) {
        const midDayDayjs = dayjs(start).add(12, 'hour');
        const midDay = midDayDayjs.valueOf();
        const endDay = dayjs(start).endOf('day').valueOf() + 1;
        const showTick = (
          // has data for the day or
          midDayDayjs.format(USA_DATE) in groupedByDate
          || (
            // in selected range
            midDayDayjs.isSameOrAfter(startDate, 'day')
            && midDayDayjs.isSameOrBefore(endDate, 'day')
          )
        );
        if (showTick) {
          // tick will show date of data
          ticks.push(midDay);
        }
        plotLines.push({
          value: midDay,
          color: CGMPlotLineColor.LIGHT_GRAY,
          width: 1,
          zIndex: 5
        });
        plotLines.push({
          value: endDay,
          color: CGMPlotLineColor.DARK_GRAY,
          width: 2,
          zIndex: 5
        });
        plotBands.push({
          from: start,
          to: endDay,
          className: `cgm-chart-band ${getClassNameForDate(midDayDayjs)}`,
          zIndex: 6
        });
        start = dayjs(start).add(1, 'day').valueOf();
      }
      const series = [{
        name: `${firstDay.format(USA_DATE)} - ${lastDay.format(USA_DATE)}`,
        data: compact(flatData),
        enableMouseTracking: false,
        turboThreshold: 0, // 0 = disabled, default 1000
      }];
      const xAxis = {
        ...getCommonXAxis(),
        labels: {
          formatter(data) { return dayjs(data.value).format(dddMMMDD); }
        },
        tickPositions: ticks,
        plotLines,
        plotBands,
        min: firstDay.valueOf(),
        max: lastDay.valueOf(),
      } as Highcharts.XAxisOptions;

      const option = { series, xAxis } as Highcharts.Options;
      options.push(option);
    });
    return options;
  }, [
    data,
    daysWithData,
    startDate,
    endDate,
    isLoading
  ]);

  const dataMax = useDeepCompareMemo(() => {
    let dataMax = 0;
    forEach(options, (option) => {
      const series = option.series?.[0] as { data: CGMChartData[] };
      dataMax = Math.max(dataMax, ...series?.data?.map((d) => d.y || 0) || []);
    });
    return dataMax;
  }, [options]);

  return (
    <div>
      {
        options?.map((option) => {
          const isShowingChartDetail = (
            (chartDetailView?.chart === option.series?.[0].name)
            && chartDetailView?.date
          );
          return (
            <div
              key={option.series?.[0].name}
              className="mt20"
            >
              <div
                className={classNames({
                  p10: true,
                  'cgm-chart-detail-show': isShowingChartDetail,
                  'cgm-chart-has-no-data': noCGMData
                })}
              >
                <CGMChartComponent
                  options={option}
                  cgmPatientContext={cgmPatientContext}
                  onChartClick={handleOnChartClick(option.series?.[0].name || '')}
                  dataMax={dataMax}
                  isCompact={showAsCompact}
                />
              </div>
              {
                isShowingChartDetail
                && (
                  <div className="cgm-chart-day-view p10">
                    <div className="cgm-chart-day-view__close-button">
                      <ClickableDiv
                        onClick={closeChartDetailView}
                        className="flex ai-c gap1"
                      >
                        <CloseOutlined />
                        Close
                      </ClickableDiv>
                    </div>
                    <div className="cgm-chart-day-view__chart">
                      <CGMChartComponent
                        key={chartDetailView.date}
                        options={getOneDayOptions(chartDetailView.date)}
                        cgmPatientContext={cgmPatientContext}
                        isCompact
                        dataMax={dataMax}
                      />
                    </div>
                  </div>
                )
              }
            </div>
          );
        })
      }
    </div>
  );
};
