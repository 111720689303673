import {
  AssignDeviceInfo,
  DeviceModelEnum,
  DeviceTypeEnum,
  PatientCgmDevice,
} from '../../../uc-api-sdk';

// to fit existing device model
export const normalizeCGMDeviceData = (cgmDevice?: PatientCgmDevice | null) => {
  if (!cgmDevice || !cgmDevice.brand) return undefined;
  return {
    ...cgmDevice,
    deviceModel: DeviceModelEnum.CGM,
    deviceType: DeviceTypeEnum.CGM
  } as AssignDeviceInfo;
};
