import { message } from 'antd';
import { FC, useCallback, useEffect } from 'react';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { useTicketInsert } from '../../../../uc-api-sdk';
import { FormSubmitButton } from '../../../../uiComponent/FormSubmitButton/FormSubmitButton';
import { CreateEditTicketFormComponent, SubmitValue } from '../../component/CreateEditTicketFormComponent/CreateEditTicketFormComponent';
import { TicketTexts } from '../../constant/text';

export interface CreateTicketContainerProps {
  onSuccess?: () => void;
  patientId?: string;
  clinicId?: string;
}
export const CreateTicketContainer: FC<CreateTicketContainerProps> = ({
  onSuccess,
  patientId,
  clinicId,
}) => {
  const updateHook = useUpdate('TICKET_CREATE');
  const useTicketService = useTicketInsert({});
  const { send } = useMixpanelContext();
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.TasksCreateTaskStart,
    eventEnd: MixpanelEvents.TasksCreateTaskEnd,
    patientId,
    parent: MixpanelEventsParents.Tasks,
  });

  useEffect(() => {
    startEvent();
  }, []);

  const createTicket = useCallback(
    async (v: SubmitValue) => {
      setTrue();
      send({ event: MixpanelEvents.TasksCreateTaskSubmit });
      const result = await useTicketService.send({
        params: {
          document: {
            memberId: v.memberId,
            description: v.description,
            assignedTo: v.assignedTo,
            dueDate: TimezoneService.getUTC(v.dueDate),
            priorityLevel: v.priorityLevel,
          },
        },
      });
      if (result?.code === 200) {
        message.success(TicketTexts.createSuccess);
        onSuccess?.();
        updateHook.updateValue();
      } else if (result?.code && result.code >= 400) {
        message.warning(TicketTexts.error);
      }
      endEvent();
      setFalse();
    },
    [useTicketService],
  );

  const initialValues: Partial<SubmitValue> = {
    memberId: patientId,
  };

  return (
    <CreateEditTicketFormComponent
      initialValues={initialValues}
      onFinish={createTicket}
      title="Create a Task"
      isLoading={isLoading}
      patientInputDisable={!!patientId}
      clinicId={clinicId}
    >
      {() => (
        <FormSubmitButton
          shouldBeDisabled={() => isLoading}
        />
      )}
    </CreateEditTicketFormComponent>
  );
};

export default CreateTicketContainer;
