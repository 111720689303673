import { Badge } from 'antd';
import dayjs from 'dayjs';
import { USA_DATE } from '../../../../constants/timeFormat';
import { AnnouncementHelper } from '../../../../helpers/announcement';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { AnnouncementTypeEnum, AnnouncementUserFeed } from '../../../../uc-api-sdk';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { AnnouncementModalContainer } from '../../container/AnnouncementModalContainer/AnnouncementModalContainer';
import './AnnouncementItemComponent.scss';

export interface AnnouncementItemComponentProps {
  announcement: AnnouncementUserFeed;
  onClose?: () => void;
  onRead?: (announcementId: string) => void
}

export const AnnouncementItemComponent = ({
  announcement,
  onClose,
  onRead
}: AnnouncementItemComponentProps) => {
  const { isOpen, open, close } = useOpen();
  const now = dayjs();
  const publishedAt = TimezoneService.calcDateTimeDayjs(announcement.publishedAt || '');
  const dayDiff = now.diff(publishedAt, 'day');
  const getTime = () => {
    if (dayDiff === 0) {
      const hourDiff = now.diff(publishedAt, 'hour');
      if (hourDiff === 0) {
        const minDiff = now.diff(publishedAt, 'minute');
        return `${minDiff} minutes ago`;
      }
      return `${hourDiff} hours ago`;
    }
    return publishedAt.format(USA_DATE);
  };

  const handleClose = () => {
    onRead?.(announcement.id || '');
    close();
    onClose?.();
  };

  return (
    <>
      <ClickableDiv
        onClick={open}
      >
        <div className="AnnouncementItemComponent flex gap2">
          <div className="announcement-type-icon">
            <Badge dot={announcement.userStatus === 'UNREAD'} className="announcement-button-badge">
              {AnnouncementHelper.getIcon(announcement.announcementType as AnnouncementTypeEnum)}
            </Badge>
          </div>
          <div className="announcement-content w100">
            <div className="flex jc-sb">
              <div className="b5">
                {announcement.title}
              </div>
              <div className="text-gray-scale-2">
                {getTime()}
              </div>
            </div>
            <div>{announcement.summary}</div>
          </div>
        </div>
      </ClickableDiv>
      <AnnouncementModalContainer
        value={announcement.content || ''}
        isOpen={isOpen}
        onCancel={handleClose}
        type={announcement.announcementType as AnnouncementTypeEnum}
      />
    </>
  );
};
