import { map } from 'lodash';
import { InputType } from '../../../Input/types';
import { CGMChartView } from '../../container/CGMVitalContainer/type';
import RadioButtonComponent from '../../../../uiComponent/RadioButtonStyleComponent/RadioButtonComponent';

import './CGMChartViewSwitchComponent.scss';

export interface CGMChartViewSwitchComponentProps extends InputType<CGMChartView | undefined> {
  isAGPDisabled?: boolean;
}

export const CGMChartViewSwitchComponent = ({
  value,
  onChange,
  isAGPDisabled,
}: CGMChartViewSwitchComponentProps) => {
  const shouldDisable = (key: CGMChartView) => {
    switch (key) {
      case CGMChartView.AGP:
        return !!isAGPDisabled;
      default:
        return false;
    }
  };

  const getText = (value: CGMChartView) => {
    switch (value) {
      case CGMChartView.DAILY_VIEW:
        return 'Daily View';
      case CGMChartView.AGP:
        return 'AGP View';
      default:
        return '';
    }
  };

  return (
    <div className="cgm-chart-view-switch">
      {
        map([
          CGMChartView.AGP,
          CGMChartView.DAILY_VIEW,
        ], (key) => (
          <RadioButtonComponent
            key={key}
            value={key}
            onClick={onChange}
            isSelected={value === key}
            disabled={shouldDisable(key)}
          >
            {getText(key)}
          </RadioButtonComponent>
        ))
      }
    </div>
  );
};
