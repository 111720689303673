import { Children, ReactNode, useMemo } from 'react';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export interface EmptyComponentWrapperProps {
  children?: ReactNode; // child should returns null as empty
  emptyDisplay?: ReactNode;
}

export const EmptyComponentWrapper = ({
  children,
  emptyDisplay = <EmptyComponent />
}: EmptyComponentWrapperProps) => {
  const checkHaveChildren = () => {
    if (children === undefined) {
      return false;
    }
    let hasChild = true;
    Children.forEach(children, (child) => {
      hasChild = hasChild && (child !== null);
    });
    return hasChild;
  };

  const hasChildren = useMemo(() => checkHaveChildren(), [children]);

  return (
    <>
      <div className={hasChildren ? '' : 'display-none'}>
        {children}
      </div>
      {hasChildren ? null : emptyDisplay}
    </>
  );
};
