import { Button } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';
import './RadioButtonComponent.scss';
import RadioGroupButtonComponent from './RadioGroupButtonComponent';

export interface RadioButtonComponentProps<T extends string> {
  value: T;
  onClick?: (value: T) => void;
  isSelected?: boolean;
  children: ReactNode;
  disabled?: boolean;
}

export const RadioButtonComponent = <T extends string>({
  value,
  onClick,
  isSelected = false,
  children,
  disabled,
}: RadioButtonComponentProps<T>) => {
  const handleClick = () => {
    onClick?.(value);
  };

  return (
    <Button
      data-testid="radio-button-component"
      onClick={handleClick}
      className={classNames({
        'radio-button-component': true,
        'active-btn': isSelected,
      })}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

RadioButtonComponent.Group = RadioGroupButtonComponent;

export default RadioButtonComponent;
