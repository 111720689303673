import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { DATE_TIME_CALENDAR } from '../../../constants/timeFormat';
import { useUpdate } from '../../../contexts/UpdateContext/UpdateContext';
import { useBillableTimeSegmentInsertReq } from '../../../features/patient/hook/useBillableTimeSegmentInsert';
import { ApiRequestHelper } from '../../../helpers/ApiRequest';
import { TimeService } from '../../../helpers/time/timeService';
import TimezoneService from '../../../helpers/timezone/timezoneService';
import { CareNoteTagEnum, useCareNoteInsert } from '../../../uc-api-sdk';
import {
  AddOfflineTimeFormComponent,
  AddOfflineTimeFormComponentProps
} from '../AddOfflineTimeFormComponent/AddOfflineTimeFormComponent';
import { ActivityTypeEnum } from '../type';

export interface SubmitValue {
  offlineActivities: ActivityTypeEnum[];
  dateOfService: string;
  timeSpent: number;
  startTime?: string;
  endTime?: string;
  callNote?: string;
  isTech?: boolean;
}
export interface AddOfflineTimeContainerProps {
  patientId: string;
  onClose?: () => void;
}

export const AddOfflineTimeContainer = ({
  patientId: memberId,
  onClose,
}: AddOfflineTimeContainerProps) => {
  const [formHook] = useForm<SubmitValue>();
  const { insertHook, send } = useBillableTimeSegmentInsertReq(memberId || '');
  const careNoteHook = useCareNoteInsert({});
  const refetchCareNotes = useUpdate('CARE_NOTE_CREATED');

  const handleSubmit = useCallback<Exclude<AddOfflineTimeFormComponentProps['onSubmit'], undefined>>(async (formValue) => {
    let startTime: string;
    let endTime: string;
    if (formValue.offlineActivities?.includes(ActivityTypeEnum.CALL)) {
      startTime = TimezoneService.calcDateTimeDayjs(formValue.dateOfService).tz('UTC')
        .startOf('D')
        .add(TimeService.getMinutesFromTimeLabel(formValue.startTime || ''), 'minutes')
        .format(DATE_TIME_CALENDAR);
      endTime = dayjs(formValue.dateOfService).tz('UTC')
        .startOf('D')
        .add(TimeService.getMinutesFromTimeLabel(formValue.endTime || ''), 'minutes')
        .format(DATE_TIME_CALENDAR);
    }

    const req = send(formValue);

    await ApiRequestHelper.tryCatch(
      req,
      {
        success: 'Offline time added!',
        error: 'Failed to add offline time!',
        onSuccess: async () => {
          formHook.resetFields();
          const careNoteReq = careNoteHook.send({
            params: {
              document: {
                tags: [CareNoteTagEnum.CALL_LOG],
                memberId,
                content: formValue.note,
                startTime,
                endTime,
                nonBillable: formValue.nonBillable,
                isOfflineCall: true,
              }
            }
          });

          await ApiRequestHelper.tryCatch(
            careNoteReq,
            {
              success: 'Care note added!',
              error: 'Failed to add care note!',
              onSuccess: () => {
                refetchCareNotes.updateValue();
              }
            },
          );
          onClose?.();
        },
      },
    );
  }, [onClose]);

  return (
    <AddOfflineTimeFormComponent
      onSubmit={handleSubmit}
      onCancel={onClose}
      isLoading={insertHook.isLoading}
      form={formHook}
    />
  );
};
