import { Checkbox, Col, Row } from 'antd';
import { map } from 'lodash';
import { AddOnServiceEnumComponent } from '../../../../enumComponent/AddOnServiceEnumComponent/AddOnServiceEnumComponent';
import { AddOnServiceEnum, VitalEnumType } from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useProgramEnrollmentForm } from '../../hook/useProgramEnrollmentForm/useProgramEnrollmentForm';

export interface AddOnServicesCheckboxComponentProps {
  selectedVitals?: VitalEnumType[];
}

export const DependentVitals = {
  [AddOnServiceEnum.CGM]: VitalEnumType.CGM,
};

export const AddOnServicesCheckboxComponent = ({
  selectedVitals = [],
}: AddOnServicesCheckboxComponentProps) => {
  const peForm = useProgramEnrollmentForm();
  return (
    <FormItem
      name={peForm.getName('addOnServices')}
    >
      <Checkbox.Group
        className="checkbox-group-btn-style programs-checkbox-group w100"
      >
        <Row gutter={10}>
          {
            map(AddOnServiceEnum, (v: AddOnServiceEnum) => (
              <Col span={12}>
                <Checkbox
                  key={v}
                  value={v}
                  className="block"
                  disabled={!selectedVitals.includes(DependentVitals[v])}
                >
                  <AddOnServiceEnumComponent
                    value={v}
                    type="service"
                  />
                </Checkbox>
              </Col>
            ))
          }
        </Row>
      </Checkbox.Group>
    </FormItem>
  );
};
