import { ReactNode } from 'react';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

export interface CGMCurrentSelectionComponentProps {
  children?: ReactNode;
  isCurrentSelection?: boolean;
}

export const CGMCurrentSelectionComponent = ({
  children,
  isCurrentSelection
}: CGMCurrentSelectionComponentProps) => (
  <div className="flex">
    {children}
    {
      isCurrentSelection
      && (
        <TextComponent color="gray2">
          (Current selection)
        </TextComponent>
      )
    }
  </div>
);
