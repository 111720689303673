import { Radio } from 'antd';
import {
  useMemo
} from 'react';
import { map } from 'lodash';
import { InputType } from '../../../Input/types';
import {
  CgmBrandEnum,
  CgmSupportedDevice
} from '../../../../uc-api-sdk';
import { CGMDeviceBrandEnumComponent } from '../../../../enumComponent/CGMDeviceBrandEnumComponent/CGMDeviceBrandEnumComponent';
import { CGMDeviceBrandChangeConfirmComponent } from '../CGMDeviceBrandChangeConfirmComponent.tsx/CGMDeviceBrandChangeConfirmComponent';
import { CGMCurrentSelectionComponent } from '../CGMDeviceFormItemComponent/CGMCurrentSelectionComponent';

export interface CGMDeviceBrandSelectorComponentProps extends InputType<CgmBrandEnum> {
  data?: CgmSupportedDevice[];
  showSwitchBrandConfirm?: boolean;
  onCloseSwitchBrandConfirm?: () => void;
  defaultValue?: CgmBrandEnum | null;
  showCurrentSelection?: boolean;
}

export const CGMDeviceBrandSelectorComponent = ({
  data,
  defaultValue,
  showCurrentSelection,
  value,
  onChange,
  showSwitchBrandConfirm,
  onCloseSwitchBrandConfirm,
}: CGMDeviceBrandSelectorComponentProps) => {
  const options = useMemo(() => (
    map(data, (option) => ({
      label: (
        <CGMCurrentSelectionComponent
          isCurrentSelection={showCurrentSelection && defaultValue === option.brand}
        >
          <CGMDeviceBrandEnumComponent value={option.brand} />
        </CGMCurrentSelectionComponent>
      ),
      value: option.brand as CgmBrandEnum,
    }))
  ), [data, defaultValue]);

  return (
    <CGMDeviceBrandChangeConfirmComponent
      open={!!showSwitchBrandConfirm}
      onClose={onCloseSwitchBrandConfirm}
    >
      <Radio.Group
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        options={options}
      />
    </CGMDeviceBrandChangeConfirmComponent>
  );
};
