import { Button } from 'antd';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { EnrolledProgramStatusEnumComponent } from '../../../../enumComponent/EnrolledProgramStatusEnumComponent/EnrolledProgramStatusEnumComponent';
import { ProgramCategoryEnumListComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { CareProtocolComponent } from '../CareProtocolComponent/CareProtocolComponent';
import { ControlLevelService } from '../../../../services/ControlLevelService';
import { AddOnServiceEnum } from '../../../../uc-api-sdk';

export interface PatientProgramStatusComponentProps {
  onClickStartEnrollment?: () => void;
  enrolledProgramService?: EnrolledProgramService;
  controlService?: ControlLevelService;
  showProgramCategory?: boolean;
}

export const PatientProgramStatusComponent = ({
  onClickStartEnrollment,
  enrolledProgramService,
  controlService,
  showProgramCategory = true,
}: PatientProgramStatusComponentProps) => {
  const enrolledPrograms = enrolledProgramService?.getPrograms();
  const enrolledProgramStatus = enrolledProgramService?.getStatus();
  const hasCGMService = enrolledProgramService?.hasAddOnService(AddOnServiceEnum.CGM);

  const status = <EnrolledProgramStatusEnumComponent value={enrolledProgramStatus} />;

  return (
    <span>
      {onClickStartEnrollment ? (
        <Button type="link" className="p0 autoH fs12" onClick={onClickStartEnrollment}>
          {status}
        </Button>
      ) : status}
      <span>
        {' - '}
        <CareProtocolComponent
          controlService={controlService}
          hasCGMService={hasCGMService}
        />
      </span>
      {
        showProgramCategory
        && !!enrolledPrograms
        && enrolledPrograms.length > 0
        && (
          <span>
            {' ('}
            <ProgramCategoryEnumListComponent
              value={enrolledPrograms}
              type="short"
              splitter=", "
            />
            )
          </span>
        )
      }
    </span>
  );
};
