interface MakeNoDataPlotBandOptions extends Highcharts.XAxisPlotBandsOptions {
  to: number;
  text?: string;
}

export const useNoData = () => {
  const makeNoDataPlotBand = ({
    to,
    text = 'No data available.', // html string is accepted
    className = 'highcharts-no-data-plot-band',
    label,
    ...options
  }: MakeNoDataPlotBandOptions) => ({
    from: -10,
    to,
    color: 'rgba(255, 255, 255, 0.7)',
    label: {
      useHTML: true,
      align: 'center',
      verticalAlign: 'middle',
      formatter() { return text; },
      ...label,
    },
    className,
    zIndex: 99,
    ...options,
  } as Highcharts.XAxisPlotBandsOptions);

  return {
    makeNoDataPlotBand,
  };
};
