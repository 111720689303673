import classNames from 'classnames';
import { ReactNode } from 'react';
import './TooltipTitleComponent.scss';
// eslint-disable-next-line import/no-unresolved
import { MathJax } from 'better-react-mathjax';
import { HTMLDisplayComponent } from '../HTMLDisplayComponent/HTMLDisplayComponent';

export interface TooltipTitleComponentProps {
  title: string | ReactNode,
  className?: string,
}
// tooltip as string and with simple format (ie new line)
// if don't want to show empty tooltip, put condition before calling this
// ie. title && <TooltipTitleComponent />
export const TooltipTitleComponent = ({
  title,
  className = '',
}: TooltipTitleComponentProps) => (
  <div
    className={classNames({
      'tooltip-title-component': true,
      [className]: !!className,
    })}
  >
    <MathJax>
      <HTMLDisplayComponent
        htmlString={`<div>${title as string}</div>`}
        nowrap
      />
    </MathJax>
  </div>
);
