import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Icons } from '../../icons/Icons';
import { AnnouncementTypeEnum } from '../../uc-api-sdk';

export class AnnouncementHelper {
  public static getIcon(type: AnnouncementTypeEnum) {
    switch (type) {
      case AnnouncementTypeEnum.ANNOUNCEMENT:
        return <Icons.MegaphoneIcon />;
      case AnnouncementTypeEnum.RELEASE:
        return <Icons.RocketIcon />;
      case AnnouncementTypeEnum.HOTFIX:
        return <Icons.WrenchIcon />;
      case AnnouncementTypeEnum.INCIDENT:
        return <Icons.BugIcon />;
      default:
        return <ExclamationCircleOutlined />;
    }
  }
}
