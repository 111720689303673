import { Select } from 'antd';
import CaretDownOutlined from '@ant-design/icons/lib/icons/CaretDownOutlined';
import { MeetingStatusComponent } from '../TagsComponent/MeetingStatusComponent/MeetingStatusComponent';
import { SelectType } from '../../../Input/types';
import { CgmGoalStatusEnum, GoalStatusEnum } from '../../../../uc-api-sdk';

import './MeetingStatusSelectComponent.scss';

export interface MeetingStatusSelectComponentProps extends SelectType<string> {
  isCgmGoal?: boolean
}

export const MeetingStatusSelectComponent = ({
  value,
  disabled,
  onChange,
  bordered = true,
  isCgmGoal = false
}: MeetingStatusSelectComponentProps) => {
  const statusOptions = isCgmGoal
    ? [
      {
        value: CgmGoalStatusEnum.MEETING,
        label: <MeetingStatusComponent.Meeting />,
      },
      {
        value: CgmGoalStatusEnum.NOT_MEETING,
        label: <MeetingStatusComponent.NotMeeting />,
      },
      {
        value: CgmGoalStatusEnum.PARTIALLY_MEETING,
        label: <MeetingStatusComponent.PartiallyMeeting />,
      },
      {
        value: CgmGoalStatusEnum.UNABLE_TO_CONCLUDE,
        label: <MeetingStatusComponent.UnableToConclude />,
      },
    ]
    : [
      {
        value: GoalStatusEnum.MEETING,
        label: <MeetingStatusComponent.Meeting />,
      },
      {
        value: GoalStatusEnum.NOT_MEETING,
        label: <MeetingStatusComponent.NotMeeting />,
      },
      {
        value: GoalStatusEnum.UNABLE_TO_CONCLUDE,
        label: <MeetingStatusComponent.UnableToConclude />,
      },
    ];

  return (
    <Select
      disabled={disabled}
      value={value === GoalStatusEnum.NONE ? undefined : value}
      className="meeting-status-select-component"
      bordered={bordered}
      placeholder="Select status"
      suffixIcon={<CaretDownOutlined />}
      onChange={onChange}
      options={statusOptions}
    />
  );
};
