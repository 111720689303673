// @ts-nocheck
interface Config {
  from: number;
  to: number;
  color: string;
}

export const useDrawYAxisWithColor = () => {
  const makeYAxisWithColor = (
    configs: Config[],
    chart: Highcharts.Chart,
    yAxisIndex = 0,
  ) => {
    configs.forEach((config) => {
      const { from, to, color } = config;
      chart.renderer.path(
        [
          'M', chart.plotLeft - 0.5, chart.yAxis[yAxisIndex].toPixels(from),
          'L', chart.plotLeft - 0.5, chart.yAxis[yAxisIndex].toPixels(to)
        ],
      ).attr({
        stroke: color,
        'stroke-width': 2,
      }).add();
    });
  };

  return {
    makeYAxisWithColor
  };
};
