import { Modal, ModalProps } from 'antd';
import Markdown from 'react-markdown';
import { AnnouncementTypeEnumComponent } from '../../../../enumComponent/AnnouncementTypeEnumComponent/AnnouncementTypeEnumComponent';
import { AnnouncementTypeEnum } from '../../../../uc-api-sdk';
import './AnnouncementModalComponent.scss';

export interface AnnouncementModalComponentProps extends ModalProps {
  value: string;
  isOpen: boolean;
  type: AnnouncementTypeEnum
}

export const AnnouncementModalComponent = ({
  value,
  isOpen,
  type,
  ...props
}: AnnouncementModalComponentProps) => (
  <Modal
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    open={isOpen}
    title={<AnnouncementTypeEnumComponent value={type} />}
    footer={null}
    wrapClassName="announcement-view-modal"
    width={800}
  >
    <div className="announcement-view-component">
      <div className="complete-announcement">
        <Markdown>{value}</Markdown>
      </div>
    </div>
  </Modal>
);
