import { ProgramCategorySearchFilterEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { EmptyString } from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface ProgramCategorySearchFilterEnumComponentProps {
  value: ProgramCategorySearchFilterEnum;
  type?: 'short' | 'long';
}

export const getProgramCategoryEnumShortText = (value?: ProgramCategorySearchFilterEnum) => {
  switch (value) {
    case ProgramCategorySearchFilterEnum.CCM_ONLY: return 'CCM-Only';
    case ProgramCategorySearchFilterEnum.RPM_ONLY: return 'RPM-Only';
    case ProgramCategorySearchFilterEnum.MNT: return 'MNT';
    case ProgramCategorySearchFilterEnum.VBC: return 'VBC';
    case ProgramCategorySearchFilterEnum.RPM_CCM: return 'RPM+CCM';
    case ProgramCategorySearchFilterEnum.RPM_APCM: return 'RPM+APCM';
    default: return EmptyString;
  }
};

export const ProgramCategorySearchFilterEnumComponent = ({
  value,
  type = 'short',
}: ProgramCategorySearchFilterEnumComponentProps) => {
  const getLongText = () => {
    switch (value) {
      case ProgramCategorySearchFilterEnum.CCM_ONLY: return 'Chronic Care Management (CCM)';
      case ProgramCategorySearchFilterEnum.RPM_ONLY: return 'Remote Patient Monitoring (RPM)';
      case ProgramCategorySearchFilterEnum.MNT: return 'Medical Nutrition Therapy (MNT)';
      case ProgramCategorySearchFilterEnum.VBC: return 'Value-based Care (VBC)';
      case ProgramCategorySearchFilterEnum.RPM_CCM: return 'RPM and CCM';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getText = () => {
    switch (type) {
      case 'long': return getLongText();
      case 'short':
      default: return getProgramCategoryEnumShortText(value);
    }
  };

  return (
    <span>{getText()}</span>
  );
};
