import { useMemo } from 'react';
import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useCgmGoalForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    tbrLevel2: {
      name: 'tbrLevel2',
      label: ''
    },
    tbrLevel2Bound: {
      name: 'tbrLevel2Bound',
      label: ''
    },
    tbrLevel1: {
      name: 'tbrLevel1',
      label: ''
    },
    tbrLevel1Bound: {
      name: 'tbrLevel1Bound',
      label: ''
    },
    tir: {
      name: 'tir',
      label: ''
    },
    tarLevel1: {
      name: 'tarLevel1',
      label: ''
    },
    tarLevel1Bound: {
      name: 'tarLevel1Bound',
      label: ''
    },
    tarLevel2: {
      name: 'tarLevel2',
      label: ''
    },
    tarLevel2Bound: {
      name: 'tarLevel2Bound',
      label: ''
    },
    status: {
      name: 'status',
      label: ''
    },
    latestNote: {
      name: 'latestNote',
      label: '',
    },
    updatedTime: {
      name: 'updatedTime',
      label: ''
    }
  }, options);

  const response = useMemo(() => ({
    ...factory,
  }), [factory]);

  return response;
};
