import { Space } from 'antd';
import React, { ReactElement } from 'react';
import { InputType } from '../../features/Input/types';
import { RadioButtonComponentProps } from './RadioButtonComponent';

export interface RadioGroupButtonComponentProps<T extends string> extends InputType<T> {
  children: ReactElement<RadioButtonComponentProps<T>>
    | ReactElement<RadioButtonComponentProps<T>>[];
  className?: string;
}

export const RadioGroupButtonComponent = <T extends string>({
  value,
  onChange,
  children,
  className,
}: RadioGroupButtonComponentProps<T>) => {
  const buttonWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        onClick: onChange,
        isSelected: value === child.props.value,
      });
    }
    return child;
  });

  return (
    <Space className={className} data-testid="radio-group-component">
      {buttonWithProps}
    </Space>
  );
};

export default RadioGroupButtonComponent;
