import { Card, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CgmMetricEnum, CgmPercentGoal } from '../../../../uc-api-sdk';
import './CgmVitalSummaryComponent.scss';
import { TagComponent, TagType } from '../../../../uiComponent/TagComponent/TagComponent';
import { CgmEmptySummaryComponent } from './CgmEmptySummaryComponent';
import { YAxis } from './CgmBarChartYAxisComponent';

export interface CgmBarChartComponentProps {
  tbrLevel2Min?: number;
  tbrLevel1Min?: number;
  tirMin?: number;
  tarLevel1Min?: number;
  tarLevel2Min?: number;
  percentGoals?: CgmPercentGoal[];
}

export const CgmBarChartComponent = ({
  tbrLevel2Min,
  tbrLevel1Min,
  tirMin,
  tarLevel1Min,
  tarLevel2Min,
  percentGoals
}: CgmBarChartComponentProps) => {
  const isDataValid = [tbrLevel2Min, tbrLevel1Min, tirMin, tarLevel1Min, tarLevel2Min].some(
    value => value !== undefined
  );

  const barChartHeight = 110;

  const tagTypes: Map<string, TagType> = new Map([
    ['Very High', 'error'],
    ['High', 'warning'],
    ['Low', 'processing'],
    ['Very Low', 'default']
  ]);

  const getMetricGoal = (
    metricName: CgmMetricEnum
  ) => percentGoals?.find(i => i.metric === metricName)?.goal;

  const totalMin = [
    tbrLevel2Min,
    tbrLevel1Min,
    tirMin,
    tarLevel1Min,
    tarLevel2Min
  ].reduce((sum, value) => (sum || 0) + (value || 0), 0);

  const tbrLevel2Goal = getMetricGoal(CgmMetricEnum.TBR_LEVEL_2);
  const tbrLevel1Goal = getMetricGoal(CgmMetricEnum.TBR_LEVEL_1);
  const tirGoal = getMetricGoal(CgmMetricEnum.TIR);
  const tarLevel1Goal = getMetricGoal(CgmMetricEnum.TAR_LEVEL_1);
  const tarLevel2Goal = getMetricGoal(CgmMetricEnum.TAR_LEVEL_2);

  const tarLevel1Bound = percentGoals?.find(
    i => i.metric === CgmMetricEnum.TAR_LEVEL_1
  )?.thresholdRange?.lowerBound ?? 140;
  const tarLevel2Bound = percentGoals?.find(
    i => i.metric === CgmMetricEnum.TAR_LEVEL_2
  )?.thresholdRange?.lowerBound ?? 200;

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    if (hours > 0) {
      return `(${hours}h ${mins}min)`;
    }
    return `(${mins}min)`;
  };

  const renderLegend = (label: string, goal: number) => {
    const type = tagTypes.get(label);
    return (
      <div className="flex CgmVitalSummaryComponent__legend">
        {type && <TagComponent type={type} iconType="solidDot" background="none" />}
        <div>{`${label} (≤${goal}%)`}</div>
      </div>
    );
  };

  const renderMetric = (value: number) => {
    if (totalMin === undefined || totalMin === 0) {
      return 0;
    }
    const percentage = totalMin !== 0 ? (Math.round((value / totalMin) * 1000) / 10).toFixed(1) : '0.0';
    return isDataValid && (
      <div className={`flex ${value === 0 ? 'text-gray-scale-3' : ''}`}>
        <p className="b6">
          {percentage}
          %
        </p>
        {formatTime(value)}
      </div>
    );
  };

  const cgmBarChartTitle = () => (
    <>
      <div className="flex">
        <div>
          <strong>Time In Range</strong>
          {' '}
          (&ge;
          {tirGoal || 70}
          %)
        </div>
        <Tooltip
          placement="top"
          overlayStyle={{ minWidth: '500px' }}
          title={(
            <div>
              <span className="bold">TIR (Time In Range)</span>
              {' '}
              is the percentage of time an individual's glucose levels from a CGM device remain
              within a target range. It provides real-time insight into daily glucose fluctuations
              and complements traditional metrics like A1C by offering a more dynamic view of
              glucose control. Every 5% increase in TIE is clinically beneficial.
            </div>
        )}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      {isDataValid && totalMin !== 0 && (
      <div className="flex">
        <TagComponent iconType="solidDot" background="none" type="success" />
        <div>{renderMetric(tirMin || 0)}</div>
      </div>
      )}
    </>
  );

  const renderBarChart = () => {
    if (totalMin === undefined || totalMin === 0) {
      return null;
    }

    const tarLevel2Height = tarLevel2Min && tarLevel2Min > 0 ? Math.max((
      tarLevel2Min / totalMin) * barChartHeight, 0.1) : 0;
    const tarLevel1Height = tarLevel1Min && tarLevel1Min > 0 ? Math.max((
      tarLevel1Min / totalMin) * barChartHeight, 0.1) : 0;
    const tirHeight = tirMin && tirMin > 0 ? Math.max((
      tirMin / totalMin) * barChartHeight, 0.1) : 0;
    const tbrLevel1Height = tbrLevel1Min && tbrLevel1Min > 0 ? Math.max((
      tbrLevel1Min / totalMin) * barChartHeight, 0.1) : 0;
    const tbrLevel2Height = tbrLevel2Min && tbrLevel2Min > 0 ? Math.max((
      tbrLevel2Min / totalMin) * barChartHeight, 0.1) : 0;

    return (
      <>
        <div className="CgmVitalSummaryComponent__label ml5">
          {/* Y axis labels for CGM bar chart */}
          <YAxis
            tarLevel2Bound={tarLevel2Bound}
            tarLevel1Bound={tarLevel1Bound}
            tarLevel2Height={tarLevel2Height}
            tarLevel1Height={tarLevel1Height}
            tirHeight={tirHeight}
            tbrLevel1Height={tbrLevel1Height}
            tbrLevel2Height={tbrLevel2Height}
            barChartHeight={barChartHeight}
          />
        </div>
        <div className="flex CgmVitalSummaryComponent__bar-chart">
          <div className="CgmVitalSummaryComponent__tar-level-2-bar" style={{ height: `${tarLevel2Height}px` }} />
          <div className="CgmVitalSummaryComponent__tar-level-1-bar" style={{ height: `${tarLevel1Height}px` }} />
          <div className="CgmVitalSummaryComponent__tir-bar" style={{ height: `${tirHeight}px` }} />
          <div className="CgmVitalSummaryComponent__tbr-level-1-bar" style={{ height: `${tbrLevel1Height}px` }} />
          <div className="CgmVitalSummaryComponent__tbr-level-2-bar" style={{ height: `${tbrLevel2Height}px` }} />
        </div>
      </>
    );
  };

  return (
    <Card className="CgmBarChartComponent">
      <div className="flex">
        <div>
          {cgmBarChartTitle()}
        </div>
        {!isDataValid ? (<CgmEmptySummaryComponent />) : (
          <>
            { renderBarChart() }
            <div className="line-height-1 mt5">
              <div className="flex">
                {renderLegend('Very High', tarLevel2Goal || 5)}
                {renderMetric(tarLevel2Min || 0)}
              </div>
              <div className="flex">
                {renderLegend('High', tarLevel1Goal || 25)}
                {renderMetric(tarLevel1Min || 0)}
              </div>
              <div className="flex">
                {renderLegend('Low', tbrLevel1Goal || 4)}
                {renderMetric(tbrLevel1Min || 0)}
              </div>
              <div className="flex">
                {renderLegend('Very Low', tbrLevel2Goal || 1)}
                {renderMetric(tbrLevel2Min || 0)}
              </div>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};
