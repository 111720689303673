import { TablePaginationConfig } from 'antd';
import { useEffect, useState } from 'react';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { AnnouncementUserFeed, useAnnouncementGetMyAnnouncements } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { AnnouncementButtonComponent } from '../../component/AnnouncementButtonComponent/AnnouncementButtonComponent';

export const AnnouncementButtonContainer = () => {
  const [size, setSize] = useState(5);

  const announcements = useAnnouncementGetMyAnnouncements({
    params: {
      searchRequest: {
        filter: {},
        pageInfo: {
          page: 1,
          size
        }
      }
    },
    options: {
      sendOnMount: true
    },
  });

  useUpdateListener('ANNOUNCEMENT_UPDATED', announcements.refetch);

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      announcements.refetch();
    }, 60000);
    return () => clearInterval(refetchInterval);
  }, []);

  const onLoadMore = useDebounce(() => {
    setSize(size + 5);
  }, 300, [size]);

  return (
    <FetchComponent
      info={announcements}
      alwaysShowChildren
    >
      {(data, info) => {
        const total = (info.pagination as TablePaginationConfig)?.total || 0;
        return (
          <div className="AnnouncementButtonContainer">
            <AnnouncementButtonComponent
              onLoadMore={total > size ? onLoadMore : undefined}
              announcements={data?.rawValue?.data?.content || [] as AnnouncementUserFeed[]}
            />
          </div>
        );
      }}
    </FetchComponent>
  );
};
