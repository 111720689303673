import {
  Button,
  Col, Form, Input, Row,
  Tooltip
} from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { RedoOutlined } from '@ant-design/icons';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { CgmGoalStatusEnum, Note } from '../../../../uc-api-sdk';
import { useCgmGoalForm } from './useCgmGoalForm';
import { FormType } from '../../../Input/types';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { MeetingStatusSelectComponent } from '../../../patient/component/MeetingStatusSelectComponent/MeetingStatusSelectComponent';
import { GoalFormTimeComponent } from '../ClinicalGoalComponent/GoalFormTimeComponent/GoalFormTimeComponent';
import { HistoryDrawerComponent } from '../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';
import { InterventionSubmitCancelButtons } from '../InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { CgmGoalTooltipComponent } from '../CgmGoalTooltipComponent/CgmGoalTooltipComponent';
import './CgmGoalFormComponent.scss';

export interface CgmGoalFormValue {
  tbrLevel2?: number;
  tbrLevel2Bound?: number;
  tbrLevel1?: number;
  tbrLevel1Bound?: number;
  tir?: number;
  tarLevel1?: number;
  tarLevel1Bound?: number;
  tarLevel2?: number;
  tarLevel2Bound?: number;
  status?: CgmGoalStatusEnum;
  latestNote?: string;
  notes?: Note[];
  updatedTime?: string;
}

const DEFAULT_CGM_PERCENT_GOAL = {
  tbrLevel2: 1,
  tbrLevel1: 4,
  tir: 70,
  tarLevel1: 25,
  tarLevel2: 5,
};

export type CgmMetricItems = 'tbrLevel2' | 'tbrLevel2Bound' | 'tbrLevel1' | 'tbrLevel1Bound' | 'tir' | 'tarLevel1' | 'tarLevel1Bound' | 'tarLevel2' | 'tarLevel2Bound' | 'status' | 'latestNote' | 'updatedTime';

export interface CgmGoalFormComponentProps extends FormType<CgmGoalFormValue> {
  onSubmit?: (v: CgmGoalFormValue) => void;
  isLoading?: boolean;
  initialValues?: CgmGoalFormValue;
}

export const CgmGoalFormComponent = ({
  onSubmit,
  initialValues,
  isLoading,
  onCancel,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />,
}: CgmGoalFormComponentProps) => {
  const cgmGoalFormInfo = useCgmGoalForm();
  const [form] = Form.useForm();

  const isInitChanged = (): boolean => (
    initialValues?.tbrLevel2 !== DEFAULT_CGM_PERCENT_GOAL.tbrLevel2
      || initialValues?.tbrLevel1 !== DEFAULT_CGM_PERCENT_GOAL.tbrLevel1
      || initialValues?.tir !== DEFAULT_CGM_PERCENT_GOAL.tir
      || initialValues?.tarLevel1 !== DEFAULT_CGM_PERCENT_GOAL.tarLevel1
      || initialValues?.tarLevel2 !== DEFAULT_CGM_PERCENT_GOAL.tarLevel2
  );
  const [isDefault, setIsDefault] = useState(!isInitChanged());

  const isPercentGoalsChanged = (): boolean => (
    Number(form.getFieldValue('tbrLevel2')) !== DEFAULT_CGM_PERCENT_GOAL.tbrLevel2
      || Number(form.getFieldValue('tbrLevel1')) !== DEFAULT_CGM_PERCENT_GOAL.tbrLevel1
      || Number(form.getFieldValue('tir')) !== DEFAULT_CGM_PERCENT_GOAL.tir
      || Number(form.getFieldValue('tarLevel1')) !== DEFAULT_CGM_PERCENT_GOAL.tarLevel1
      || Number(form.getFieldValue('tarLevel2')) !== DEFAULT_CGM_PERCENT_GOAL.tarLevel2
  );

  const resetPercentGoals = (): void => {
    Object.keys(DEFAULT_CGM_PERCENT_GOAL).forEach((key) => {
      form.setFieldValue(key, DEFAULT_CGM_PERCENT_GOAL[
        key as keyof typeof DEFAULT_CGM_PERCENT_GOAL]);
    });
    setIsDefault(true);
  };

  const onFormSubmit = (v: CgmGoalFormValue) => {
    onSubmit?.({
      ...v,
    });
  };
  const [currentDate] = useState(dayjs());

  const handleInputChange = () => {
    setIsDefault(!isPercentGoalsChanged());
  };

  const renderFormInput = (metricItem: CgmMetricItems) => (
    <FormItem
      className="mr12"
      info={cgmGoalFormInfo.getInfo(metricItem as CgmMetricItems)}
      rules={[
        {
          validator: (_, value) => {
            if (/^[0-9]*$/.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Please enter numeric values only.'));
          },
        },
      ]}
    >
      <Input
        addonBefore={metricItem === 'tir' ? '>' : '<'}
        addonAfter="%"
        size="small"
        onChange={handleInputChange}
        className="CgmGoalFormComponent__input-number"
      />
    </FormItem>
  );

  const renderFormItem = (metricItem: CgmMetricItems) => {
    let title;
    let sign;
    let bound;
    if (metricItem === 'tbrLevel2') {
      title = 'TBR';
      sign = '< ';
      bound = initialValues?.tbrLevel2Bound;
    } else if (metricItem === 'tbrLevel1') {
      title = 'TBR';
      sign = '< ';
      bound = initialValues?.tbrLevel1Bound;
    } else if (metricItem === 'tir') {
      title = 'TIR';
      sign = '';
      bound = '';
    } else if (metricItem === 'tarLevel1') {
      title = 'TAR';
      sign = '> ';
      bound = initialValues?.tarLevel1Bound;
    } else if (metricItem === 'tarLevel2') {
      title = 'TAR';
      sign = '> ';
      bound = initialValues?.tarLevel2Bound;
    }

    return (
      <Col span={4} className="CgmGoalFormComponent">
        <div className="flex text-gray-scale-2 fs12">
          <p className="mb0">
            {title}
            {' '}
            {sign}
            {' '}
            {bound}
          </p>
        </div>
        {renderFormInput(metricItem)}
      </Col>
    );
  };

  return (
    <Form
      name="cgmPercentGoals"
      form={form}
      layout="vertical"
      disabled={isLoading}
      onFinish={onFormSubmit}
      initialValues={initialValues}
    >
      <FixedComponent>
        {/* title */}
        <div className="flex text-gray-scale-2 mb12 fs12">
          <div>
            CGM Percent in Range
          </div>
          <TooltipComponent
            title={<CgmGoalTooltipComponent />}
            placement="topLeft"
            type="info-icon"
            color="white"
            overlayStyle={{ minWidth: '620px' }}
          />
        </div>

        {/* CGM percent goal */}
        <Row>
          <div className="flex ml12 jc-sb">
            {renderFormItem('tbrLevel2')}
            {renderFormItem('tbrLevel1')}
            {renderFormItem('tir')}
            {renderFormItem('tarLevel1')}
            {renderFormItem('tarLevel2')}
          </div>
          <Tooltip
            className="CgmGoalFormComponent__reset-bottom"
            placement="top"
            title="Reset to default"
          >
            <Button
              disabled={isDefault}
              type="text"
              icon={(
                <span className={!isDefault ? 'CgmGoalFormComponent__reset-button-icon' : ''}>
                  <RedoOutlined />
                </span>
              )}
              onClick={resetPercentGoals}
            />
          </Tooltip>

          {/* CGM status and updated time */}
          <div className="flex CgmGoalFormComponent__status">
            <p className="text-gray-scale-2 mx5 mt5"> Status </p>
            <FormItem
              info={cgmGoalFormInfo.getInfo('status')}
              className="goal-form-meeting-status"
            >
              <MeetingStatusSelectComponent isCgmGoal />
            </FormItem>
            <div className="mt5">
              <GoalFormTimeComponent
                value={initialValues?.updatedTime ?? currentDate.toISOString()}
              />
            </div>
          </div>
        </Row>
        <p className="text-gray-scale-2 fs12 mt32"> Additional Comments</p>
        <Form.Item name="latestNote">
          <TextArea placeholder={form.getFieldValue('latestNote') || 'Enter comment'} />
        </Form.Item>
        {
            initialValues?.notes?.length !== undefined && initialValues?.notes?.length !== 0 && (
            <HistoryDrawerComponent history={initialValues?.notes} />
            )
          }
        {formButtons}
      </FixedComponent>
    </Form>
  );
};
