import {
  useState, useRef, useEffect
} from 'react';
import { Form } from 'antd';
import { CGMDeviceBrandSelectorComponent, CGMDeviceBrandSelectorComponentProps } from '../CGMDeviceBrandSelectorComponent/CGMDeviceBrandSelectorComponent';
import { CGMDeviceModelSelectorComponent } from '../CGMDeviceModelSelectorComponent/CGMDeviceModelSelectorComponent';
import { CGMFormSubmitValue, useCGMDeviceForm } from './useCGMDeviceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import {
  CgmBrandEnum,
  CgmDeviceStatusEnum,
  CgmModelEnum,
  CgmSupportedDevice,
  PatientCgmDevice,
} from '../../../../uc-api-sdk';
import { CGMDeviceAccountLinkingContainer } from '../../container/CGMDeviceAccountLinkingContainer/CGMDeviceAccountLinkingContainer';

import './CGMDeviceFormItemComponent.scss';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { useRefState } from '../../../../hooks/useRefState/useRefState';

export interface CGMDeviceFormItemComponentProps {
  patientId: string;
  deviceData: CgmSupportedDevice[];
  onValuesChange?: (
    changedValues: Partial<CGMFormSubmitValue>,
    allValues: CGMFormSubmitValue
  ) => void;
  showCurrentSelection?: boolean;
}

export const CGMDeviceFormItemComponent = ({
  patientId,
  deviceData,
  onValuesChange,
  showCurrentSelection = true
}: CGMDeviceFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const cgmDeviceForm = useCGMDeviceForm({ form });
  const [initialValue, setInitialValue] = useState<PatientCgmDevice | undefined>(undefined);
  const [
    getModelByBrand,
    setModelByBrand,
  ] = useRefState<{ [key in CgmBrandEnum]?: CgmModelEnum }>();

  const {
    isOpen: showSwitchBrandConfirm,
    open: setShowSwitchBrandConfirm,
    close: closeSwitchBrandConfirm,
  } = useOpen();
  const timeoutOpen = useRef<NodeJS.Timeout>();
  const timeoutClose = useRef<NodeJS.Timeout>();

  const handleValuesChange = (
    changed: Partial<CGMFormSubmitValue>,
  ) => {
    onValuesChange?.(changed, cgmDeviceForm.form.getFieldsValue());
  };

  const handleSetModel = (model?: CgmModelEnum) => {
    setModelByBrand((prev) => {
      const currentBrand = cgmDeviceForm.getValue('brand', cgmDeviceForm.form.getFieldValue);
      return {
        ...prev || {},
        [currentBrand]: model,
      };
    });
    handleValuesChange({
      [cgmDeviceForm.getName('model')]: model
    });
  };

  const handleOnBrandChange = (prevBrand: CgmBrandEnum): CGMDeviceBrandSelectorComponentProps['onChange'] => (
    value
  ) => {
    const prevLinkingStatus = cgmDeviceForm.form.getFieldsValue().linkingStatus;
    if (prevBrand !== undefined && prevLinkingStatus === CgmDeviceStatusEnum.LINK_SUCCESS) {
      clearTimeout(timeoutOpen.current);
      timeoutOpen.current = setTimeout(setShowSwitchBrandConfirm, 800);
    }
    const selectedModel = getModelByBrand()?.[value as CgmBrandEnum] || null;
    cgmDeviceForm.form.setFieldValue(
      cgmDeviceForm.getName('model'),
      selectedModel
    );
    handleValuesChange({
      [cgmDeviceForm.getName('brand')]: value,
      [cgmDeviceForm.getName('model')]: selectedModel
    });
  };

  useEffect(() => {
    const initialValue = cgmDeviceForm.form.getFieldsValue() as CGMFormSubmitValue;
    if (showCurrentSelection) {
      setInitialValue(initialValue);
    }
    if (initialValue?.brand) {
      setModelByBrand({ [initialValue.brand]: initialValue.model });
    }
  }, []);

  useEffect(() => {
    if (showSwitchBrandConfirm) {
      clearTimeout(timeoutClose.current);
      timeoutClose.current = setTimeout(closeSwitchBrandConfirm, 5000);
    }
  }, [showSwitchBrandConfirm]);

  useEffect(() => () => {
    clearTimeout(timeoutOpen.current);
    clearTimeout(timeoutClose.current);
  }, []);

  const handleStatusChange = (status?: CgmDeviceStatusEnum) => {
    cgmDeviceForm.form.setFieldValue(
      cgmDeviceForm.getName('linkingStatus'),
      status
    );
    handleValuesChange({ linkingStatus: status });
  };

  return (
    <div className="cgm-device-form-item">
      <FormItem noStyle shouldUpdate>
        {
          ({ getFieldValue }) => {
            const prevBrand = cgmDeviceForm.getValue('brand', getFieldValue);
            return (
              <FormItem
                info={cgmDeviceForm.getInfo('brand')}
                required
              >
                <CGMDeviceBrandSelectorComponent
                  data={deviceData}
                  onChange={handleOnBrandChange(prevBrand)}
                  showSwitchBrandConfirm={showSwitchBrandConfirm}
                  onCloseSwitchBrandConfirm={closeSwitchBrandConfirm}
                  defaultValue={initialValue?.brand}
                  showCurrentSelection={showCurrentSelection}
                />
              </FormItem>
            );
          }
        }
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={cgmDeviceForm.shouldUpdate(['brand'])}
      >
        {
          ({ getFieldValue }) => {
            const show = cgmDeviceForm.shouldShowModel(getFieldValue);
            const brand = cgmDeviceForm.getValue('brand', getFieldValue);
            if (!show) return null;
            return (
              <FormItem
                info={cgmDeviceForm.getInfo('model')}
              >
                <CGMDeviceModelSelectorComponent
                  brand={brand}
                  data={deviceData}
                  onChange={handleSetModel}
                  defaultValue={initialValue?.model}
                  showCurrentSelection={showCurrentSelection}
                />
              </FormItem>
            );
          }
        }
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={cgmDeviceForm.shouldUpdate(['brand', 'model'])}
      >
        {
          ({ getFieldValue }) => {
            const show = cgmDeviceForm.shouldShowLinkingStatus(getFieldValue);
            if (!show) return null;
            const brand = cgmDeviceForm.getValue('brand', getFieldValue) as CgmBrandEnum;
            const model = cgmDeviceForm.getValue('model', getFieldValue) as CgmModelEnum;
            return (
              <FormItem info={cgmDeviceForm.getInfo('linkingStatus')}>
                <CGMDeviceAccountLinkingContainer
                  patientId={patientId}
                  brand={brand}
                  model={model}
                  onChange={handleStatusChange}
                />
              </FormItem>
            );
          }
        }
      </FormItem>
    </div>
  );
};
