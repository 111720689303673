import { useEffect } from 'react';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';
import { useMpTrackingHelper } from '../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { AddEditMedicationFormComponent } from '../component/AddEditMedicationFormComponent/AddEditMedicationFormComponent';
import { MedicationType } from '../type/type';

export interface AddMedicationContainerProps {
  onSubmit: (v: MedicationType) => void;
  close?: () => void;
  patientId?: string;
}

export const AddMedicationContainer = ({
  onSubmit,
  close,
  patientId,
}: AddMedicationContainerProps) => {
  const { send } = useBillableTimeInterventionComp();
  const { send: sendMixpanel } = useMixpanelContext();
  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.AddMedicationStart,
    eventEnd: MixpanelEvents.AddMedicationEnd,
    patientId,
  });

  useEffect(() => {
    startEvent();
  }, []);

  const handleSubmit = (v: MedicationType) => {
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.Transcribing,
      detail:
        BillableTimeInterventionDetailEnum.TranscribingVisitTranscribeMedicationListAddMedication,
    });
    sendMixpanel({ event: MixpanelEvents.AddMedication });
    onSubmit(v);
    endEvent();
    close?.();
  };

  return (
    <AddEditMedicationFormComponent
      onSubmit={handleSubmit}
    />
  );
};
