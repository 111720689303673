import { Button } from 'antd';
import { AnnouncementUserFeed } from '../../../../uc-api-sdk';
import { AnnouncementItemComponent } from '../AnnouncementItemComponent/AnnouncementItemComponent';
import './AnnouncementListComponent.scss';

export interface AnnouncementListComponentProps {
  announcements?: AnnouncementUserFeed[]
  onLoadMore?: () => void
  onRead?: (announcementId: string) => void
}

export const AnnouncementListComponent = ({
  announcements = [],
  onLoadMore,
  onRead
}: AnnouncementListComponentProps) => (
  <div className="AnnouncementListComponent border-t">
    <div className="flex fd-c gap3 py20 announcement-list">
      {announcements?.map((announcement) => (
        <AnnouncementItemComponent
          announcement={announcement}
          key={announcement.id}
          onRead={onRead}
        />
      ))}
    </div>
    {onLoadMore && <div className="flex jc-c p10"><Button type="link" onClick={onLoadMore}>See previous updates</Button></div>}
  </div>
);
