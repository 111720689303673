import { Row, Col } from 'antd';
import { CgmGoalStatusEnum } from '../../../../uc-api-sdk';
import { TitleContentTextComponent } from '../../../../uiComponent/TitleContentTextComponent/TitleContentTextComponent';
import { MeetingStatusSelectComponent } from '../../../patient/component/MeetingStatusSelectComponent/MeetingStatusSelectComponent';
import './CgmGoalCardComponent.scss';
import { GoalFormTimeComponent } from '../ClinicalGoalComponent/GoalFormTimeComponent/GoalFormTimeComponent';
import { HistoryDrawerComponent } from '../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';
import { CgmGoalFormValue, CgmMetricItems } from '../CgmGoalFormComponent/CgmGoalFormComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { CgmGoalTooltipComponent } from '../CgmGoalTooltipComponent/CgmGoalTooltipComponent';

interface CgmGoalCardComponentProps {
  cgmGoal: CgmGoalFormValue;
}

export const CgmGoalCardComponent = ({
  cgmGoal,
}: CgmGoalCardComponentProps) => {
  const METRIC_CONFIG = {
    tbrLevel2: {
      title: 'TBR', titleSign: ' < ', boundKey: 'tbrLevel2Bound', goalKey: 'tbrLevel2'
    },
    tbrLevel1: {
      title: 'TBR', titleSign: ' < ', boundKey: 'tbrLevel1Bound', goalKey: 'tbrLevel1'
    },
    tir: {
      title: 'TIR', titleSign: '', boundKey: '', goalKey: 'tir'
    },
    tarLevel1: {
      title: 'TAR', titleSign: ' > ', boundKey: 'tarLevel1Bound', goalKey: 'tarLevel1'
    },
    tarLevel2: {
      title: 'TAR', titleSign: ' > ', boundKey: 'tarLevel2Bound', goalKey: 'tarLevel2'
    },
  } as const;

  const renderMetricPercentGoal = (metricItem: CgmMetricItems) => {
    const config = METRIC_CONFIG[metricItem as keyof typeof METRIC_CONFIG];
    const bound = config.boundKey ? cgmGoal?.[config.boundKey] : '';
    const goal = cgmGoal?.[config.goalKey];

    return (
      <Col span={6}>
        <TitleContentTextComponent
          title={`${config.title}${config.titleSign}${bound}`}
          content={(
            <>
              {metricItem === 'tir' ? '> ' : '< '}
              {goal}
              %
            </>
          )}
        />
      </Col>
    );
  };

  return (
    <div className="CgmGoalCardComponent">
      <div className="flex text-gray-scale-2 fs12 mb12">
        <div>
          CGM Percent in Range
        </div>
        <TooltipComponent
          title={<CgmGoalTooltipComponent />}
          placement="topLeft"
          type="info-icon"
          color="white"
          overlayStyle={{ minWidth: '620px' }}
        />
      </div>
      <div className="ml12">
        <Row gutter={10} className="jc-sb">
          <div className="flex">
            {renderMetricPercentGoal('tbrLevel2')}
            {renderMetricPercentGoal('tbrLevel1')}
            {renderMetricPercentGoal('tir')}
            {renderMetricPercentGoal('tarLevel1')}
            {renderMetricPercentGoal('tarLevel2')}
          </div>

          <div className="flex">
            <div className="meeting-status">
              <p>Status:</p>
              <MeetingStatusSelectComponent
                bordered={false}
                disabled
                value={cgmGoal?.status as CgmGoalStatusEnum}
                isCgmGoal
              />
              <GoalFormTimeComponent value={cgmGoal?.updatedTime as string} />
            </div>
          </div>
        </Row>
      </div>
      <div className="text-gray-scale-2 fs12 mb5">
        Additional Comments
      </div>
      {cgmGoal?.latestNote || 'N/A'}
      {
        cgmGoal?.notes && cgmGoal?.notes.length !== 0 && (
          <HistoryDrawerComponent history={cgmGoal?.notes} />
        )
      }
    </div>
  );
};
