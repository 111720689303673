import EnvConfig from '../../configs/envConfig/envConfig';
import { StorageKeyEnum, useSessionStorage } from '../../hooks/useSessionStorage';
import {
  adminPortalRoles, billerPortalRoles,
  carePortalRoles, careRelatedRoles, clinicBillingRoles, internalRoleTypes
} from '../../lib/constants/roles';
import { RoleTypeEnum } from '../../uc-api-sdk';
import { VisibilityProcessorArgs } from './VisibilityContext';

// eslint-disable-next-line import/prefer-default-export
export const defaultVisibilityProcessor = ({ loggedInUserContext }: VisibilityProcessorArgs) => {
  const {
    doesUserRoleIncludes,
    isProvider,
    isClinicalStaff,
    isInternalStaff,
    isClinicManager
  } = loggedInUserContext;
  const [
    enableInsight,
  ] = useSessionStorage(StorageKeyEnum.ENABLE_INSIGHT);
  const isInternalUser = doesUserRoleIncludes(internalRoleTypes);
  const isInsightEnabled = EnvConfig.isInsightEnabled || enableInsight;
  const isCareUser = doesUserRoleIncludes(careRelatedRoles);

  return {
    showSampleComponent1: loggedInUserContext.doesUserRoleIncludes([
      RoleTypeEnum.RD,
      RoleTypeEnum.MA,
    ]),
    showSampleComponent2: loggedInUserContext.doesUserRoleIncludes([RoleTypeEnum.SUPER_ADMIN]),
    showSampleComponentExtended: false,
    showOnsiteInfo: isInternalUser,
    showMTPR: loggedInUserContext.doesUserRoleIncludes([
      RoleTypeEnum.RD,
      RoleTypeEnum.HC,
    ]),
    showPlatformCarePortal: loggedInUserContext.doesUserRoleIncludes(carePortalRoles),
    showPlatformAdminPortal: loggedInUserContext.doesUserRoleIncludes(adminPortalRoles),
    showPlatformBillerPortal: loggedInUserContext.doesUserRoleIncludes(billerPortalRoles),
    showCalendarMyGoogleEvent: isInternalUser,
    showOtherClinics: isInternalUser,
    showOnCallCoverageMode: isInternalUser,
    sideMenuButtons: {
      showHomePage: !isClinicalStaff,
      showVisitsAndEvents: isCareUser,
      showPatients: isCareUser,
      showPatientCare: !isClinicalStaff,
      showTask: isCareUser,
      showUtilities: !isClinicalStaff,
      showInsight: isInsightEnabled && (isClinicManager || !isClinicalStaff),
      showMedicalAlerts: isProvider,
      showClinicBilling: doesUserRoleIncludes(clinicBillingRoles)
    },
    showMedicalAlertEngineEdit: loggedInUserContext.isSuperAdmin,
    showHeaderItems: isProvider || isInternalStaff,
  };
};
