import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { findIndex } from 'lodash';
import { useMemo, useState } from 'react';
import { InsuranceCardSubmitValue, InsuranceEligibilityRequestSubmitValue, InsuranceSubmitValue } from '../../../../hooks/formHook/useInsuranceForm';
import {
  EligibilityReport, Patient,
  useInsuranceProviderVerifyEligibility,
  usePatientUpdate
} from '../../../../uc-api-sdk';
import { Title } from '../../../../uiComponent/Title/Title';
import { InsuranceEligibilityVerifyFormComponent, InsuranceEligibilityVerifyFormComponentProps } from '../../component/InsuranceEligibilityVerifyFormComponent/InsuranceEligibilityVerifyFormComponent';
import { WaystarLoadingComponent } from '../../component/WaystarComponent/WaystarLoadingComponent/WaystarLoadingComponent';

import { DATE_STANDARD, USA_DATE } from '../../../../constants/timeFormat';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useInsuranceFormContext } from '../../context/InsuranceFormContext';
import { useInsuranceUpdateHandler } from '../../hook/insuranceUpdateHandler';
import './InsuranceEligibilityVerifyFormContainer.scss';

export interface InsuranceEligibilityVerifySyncStatus {
  isInsuranceSynced: boolean;
  isProfileSynced: boolean;
}

export interface InsuranceEligibilityVerifyFormContainerProps {
  patient: Patient;
  insurance: Partial<InsuranceCardSubmitValue>;
  patientInsurance?: InsuranceSubmitValue; // all insurances
  onVerified?: (
    report?: EligibilityReport,
    request?: InsuranceEligibilityRequestSubmitValue,
    syncStatus?: InsuranceEligibilityVerifySyncStatus,
  ) => void;
  autoSync?: boolean; // auto sync patient profile
}

export const InsuranceEligibilityVerifyFormContainer = ({
  patient,
  insurance,
  patientInsurance,
  onVerified,
  autoSync = true,
}: InsuranceEligibilityVerifyFormContainerProps) => {
  const formContext = useInsuranceFormContext();
  const verifyEligibility = useInsuranceProviderVerifyEligibility({});
  const patientInfo = usePatientUpdate({});
  const { updateInsurance } = useInsuranceUpdateHandler(patient.id || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const insuranceInfo = patientInsurance || formContext.insuranceFormValues;

  const initialValues = useMemo(() => ({
    firstName: patient.profile?.firstName,
    lastName: patient.profile?.lastName,
    birthday: patient.profile?.birthday ? dayjs(patient.profile.birthday) : null,
    ...insurance,
    // inquiringProvider: {
    //   providerName: IHEALTH_INQUIRING_PROVIDER_OPTION.label,
    //   providerNpi: IHEALTH_INQUIRING_PROVIDER_OPTION.value
    // },
  } as InsuranceEligibilityVerifyFormComponentProps['initialValues']), [patient, insurance]);

  const syncInsurance = (
    eligibilityReport?: EligibilityReport,
    eligibilityRequestValue?: InsuranceEligibilityRequestSubmitValue,
    next?: (isSuccess?: boolean) => void,
  ) => {
    const {
      eligibilityStatus,
      inquiryId: wayStarInquiryId,
    } = eligibilityReport || {};
    const currInsuranceIdx = findIndex(
      insuranceInfo.insuranceList,
      (item) => item.insuranceProviderId === insurance.insuranceProviderId
    );
    const newInsuranceList = [...(insuranceInfo.insuranceList || [])];
    newInsuranceList.splice(currInsuranceIdx, 1, {
      ...insurance,
      ...eligibilityRequestValue,
      eligibilityStatus,
      wayStarInquiryId,
    } as InsuranceCardSubmitValue);
    updateInsurance(
      {
        ...insuranceInfo,
        insuranceList: newInsuranceList,
      },
      {
        success: '',
        error: 'Failed to sync insurance',
        onSuccess: () => next?.(true),
        onError: () => next?.(false),
      },
    );
  };

  const syncPatientProfile = async (newValue: InsuranceEligibilityRequestSubmitValue) => {
    const isPatientProfileChanged = (
      patient.profile?.firstName !== newValue.firstName
      || patient.profile?.lastName !== newValue.lastName
      || (
        dayjs(patient.profile?.birthday).format(USA_DATE)
        !== dayjs(newValue.birthday).format(USA_DATE)
      )
    );
    if (patient.id && isPatientProfileChanged) {
      const req = patientInfo.send({
        params: {
          memberId: patient.id,
          request: {
            profile: {
              firstName: newValue.firstName,
              lastName: newValue.lastName,
              birthday: dayjs(newValue.birthday).format(DATE_STANDARD),
            }
          }
        }
      });
      const res = await ApiRequestHelper.tryCatch(req, {
        success: '',
        error: 'Failed to sync patient profile.',
      });
      if (res && res.code === 200) {
        return true;
      }
    }
    return false;
  };

  const handleOnSuccess = (
    requestValue: InsuranceEligibilityRequestSubmitValue,
    eligibilityReport?: EligibilityReport,
  ) => {
    const handleOnVerified = (syncStatus: InsuranceEligibilityVerifySyncStatus) => {
      onVerified?.(
        eligibilityReport,
        requestValue,
        syncStatus,
      );
      setIsSubmitting(false);
    };
    if (!autoSync) {
      handleOnVerified({
        isInsuranceSynced: false,
        isProfileSynced: false,
      });
      return;
    }
    syncInsurance(
      eligibilityReport,
      requestValue,
      async (isSuccess) => {
        let isInsuranceSynced = false;
        let isProfileSynced = false;
        if (isSuccess) {
          isInsuranceSynced = true;
          isProfileSynced = await syncPatientProfile(requestValue);
        }
        handleOnVerified({
          isInsuranceSynced,
          isProfileSynced,
        });
      }
    );
  };

  const cleanErrorMessage = (message?: string | null) => {
    if (!message) {
      return '';
    }
    let cleanMsg = message.replaceAll(/\\/g, '');
    cleanMsg = cleanMsg.replace(/"</g, '<');
    cleanMsg = cleanMsg.replace(/>"/g, '>');
    const startingIndex = cleanMsg.indexOf('<');
    return cleanMsg.slice(startingIndex);
  };

  const handleSubmit: InsuranceEligibilityVerifyFormComponentProps['onSubmit'] = async (value) => {
    const requestObject = {
      payerName: value.provider,
      payerId: value.insuranceProviderId,
      firstName: value.firstName,
      lastName: value.lastName,
      birthday: dayjs(value.birthday).format(USA_DATE),
      policyNumber: value.policyNumber,
      patientRelationship: value.patientRelationship,
      benefitOrder: value.benefitOrder,
      providerName: value.inquiringProvider?.providerName,
      providerNpi: value.inquiringProvider?.providerNpi,
    };

    setIsSubmitting(true);
    try {
      const res = await verifyEligibility.send({
        params: {
          // fake id, this is to support pt creation flow
          memberId: patient.id || '111111111111111111111111',
          request: requestObject
        }
      });
      if (res && res.code === 500) {
        const cleanMsg = cleanErrorMessage(res.msg);
        throw new Error(cleanMsg);
      }
      const eligibilityReport = res?.data ?? undefined;
      handleOnSuccess(value, eligibilityReport);
    } catch (error) {
      // const errorDetails = (
      //   (error as Error)?.message
      //   || 'Not available'
      // );
      Modal.error({
        icon: <ExclamationCircleFilled color="var(--secondary-red)" />,
        title: 'Verification query failed.',
        width: 600,
        closable: true,
        content: (
          <div className="flex fd-c">
            <div className="mb20">
              The information you entered is not valid. Please double-check.
            </div>
            {/* <Collapse>
              <Collapse.Panel key="details" header="More Details">
                <HTMLDisplayComponent htmlString={errorDetails} />
              </Collapse.Panel>
            </Collapse> */}
          </div>
        ),
      });
      setIsSubmitting(false);
    }
  };

  const debounceHandleSubmit = useDebounce(handleSubmit, 500, [handleSubmit]);

  return (
    <>
      {
        (verifyEligibility.isLoading || isSubmitting)
        && (
          <WaystarLoadingComponent>
            Verification in progress, please wait...
          </WaystarLoadingComponent>
        )
      }
      <div
        className={classNames({
          'insurance-eligibility-verify-form': true,
          // to keep unsaved input values
          'display-none': (verifyEligibility.isLoading || isSubmitting),
        })}
      >
        <Title>
          Verify Eligibility
        </Title>
        <InsuranceEligibilityVerifyFormComponent
          initialValues={initialValues}
          onSubmit={debounceHandleSubmit}
        />
      </div>
    </>
  );
};
