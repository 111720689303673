import { CgmDataPoint, CgmThresholdRange } from '../../../../uc-api-sdk';

export enum CGMChartView {
  DAILY_VIEW = 'DAILY_VIEW',
  AGP = 'AGP'
}

export interface CGMRange extends CgmThresholdRange {
  value: number;
  color?: string;
}

export interface CGMChartData {
  x?: string | number;
  y?: number;
  custom?: CgmDataPoint;
  marker?: { enabled: boolean, className: string };
}

export type CGMSeries = {
  name?: string;
  data: CGMChartData[];
}
