import { message } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import {
  LabResultClass,
  useLabResultGet,
  useLabResultUpdate
} from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { CreateEditLabResultFormComponent, SubmitValue } from '../../component/CreateEditLabResultFormComponent/CreateEditLabResultFormComponent';
import { LabResultDetailTableComponent } from '../../component/LabResultDetailTableComponent/LabResultDetailTableComponent';
import { EditLabResultMode, TranscribeTexts } from '../../constants';
import { useLabResultHelper } from '../../hook/useLabResultHelper';
import { useLabResultTemplatesSearch } from '../../hook/useLabResultTemplatesSearch';

export interface EditLabResultContainerProps {
  id?: string;
  templateId?: string;
  lab?: LabResultClass;
  onCancel?: () => void;
  mode?: EditLabResultMode;
  patientId?: string;
  isModalView?: boolean;
}

export const EditLabResultContainer = ({
  id,
  templateId,
  lab,
  onCancel,
  mode = 'Edit',
  patientId,
  isModalView = false,
}: EditLabResultContainerProps) => {
  const service = useLabResultUpdate({});
  const { send: sendMixpanel } = useMixpanelContext();
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const updateHook = useUpdate('LAB_EDITED');
  const { send } = useBillableTimeInterventionComp();
  const { parseEditLabResultItem } = useLabResultHelper();
  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.EditLabResultStart,
    eventEnd: MixpanelEvents.EditLabResultEnd,
    patientId,
  });

  useEffect(() => {
    startEvent();
  }, []);

  const onEdit = useCallback(
    async (v: SubmitValue) => {
      // not editing anything
      if (isEmpty(v)) {
        return;
      }
      // missing id
      if (!id) {
        return;
      }
      setTrue();
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Transcribing,
        detail:
          BillableTimeInterventionDetailEnum.TranscribingVisitTranscribeLabResultEditLabResultSave,
      });

      sendMixpanel({ event: MixpanelEvents.EditLabResult });
      const result = await service.send({
        params: {
          document: parseEditLabResultItem(v),
          id,
        },
      });
      if (result?.code === 200) {
        message.success(TranscribeTexts.editSuccess);
        updateHook.updateValue();
      } else if (result?.code && result.code >= 400) {
        message.warning(TranscribeTexts.error);
      }
      endEvent();
      setFalse();
      onCancel?.();
    },
    [service, lab?.id, onCancel],
  );

  const request = useLabResultGet({
    params: {
      id: id ?? '',
    },
  });

  const { template, items } = useLabResultTemplatesSearch(templateId);

  const {
    initialValues,
    resultDetails,
  } = useMemo(() => {
    if (!request.isLoading && request.data !== undefined
      && !template.isLoading && template.data !== undefined) {
      const data = request.data?.data;
      let i = 1;
      const resultDetails = data?.results?.map(v => {
        i += 1;
        const itemTemplate = items?.find((r) => {
          if (r.name === v.name) {
            return true;
          }
          return false;
        });
        const b = {
          ...v,
          referenceLevels: itemTemplate?.referenceLevels,
          dbKey: i.toString(),
        };
        return b;
      }) || [];

      const initialValues = {
        testDate: data?.dateCollected
          ? moment(data.dateCollected).startOf('day')
          : undefined,
        templateName: data?.templateName ?? '',
      };

      return {
        initialValues,
        resultDetails,
      };
    }
    return {
      initialValues: {},
      resultDetails: [],
    };
  }, [
    request.isLoading, request.data,
    template.isLoading, template.data,
  ]);

  if (request.isLoading || template.isLoading) {
    return <LoadingOverlayComponent />;
  }

  return (
    !isModalView
      ? (
        <CreateEditLabResultFormComponent
          id={id}
          labResultItems={resultDetails}
          template={templateId ?? undefined}
          onFinish={onEdit}
          onCloseDrawer={onCancel}
          mode={mode}
          initialValues={initialValues}
          isLoading={isLoading}
          testDate={moment(request.data?.data?.dateCollected ?? undefined)}
          templateName={request.data?.data?.templateName ?? ''}
        />
      )
      : (
        <LabResultDetailTableComponent
          labResultItem={resultDetails}
          template={templateId}
          isValueEditable={false}
        />
      )
  );
};
