import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled
} from '@ant-design/icons';
import './CGMDeviceAccountLinkingStatusComponent.scss';
import dayjs from 'dayjs';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { CgmDeviceStatusEnum, PatientCgmDeviceDto } from '../../../../uc-api-sdk';

export interface CGMDeviceAccountLinkingStatusComponentProps {
  linkingStatus?: PatientCgmDeviceDto | null;
  onLearnMoreClick?: () => void;
}

export const CGMDeviceAccountLinkingStatusComponent = ({
  linkingStatus,
  onLearnMoreClick
}: CGMDeviceAccountLinkingStatusComponentProps) => {
  const {
    status,
    accountLinkedAt
  } = linkingStatus || {};

  const renderMessage = () => {
    switch (status) {
      case CgmDeviceStatusEnum.LINK_SENT:
        return (
          <div className="linking-status-info secondary-gray">
            <InfoCircleFilled />
            {' '}
            Request sent, account linking pending...
          </div>
        );
      case CgmDeviceStatusEnum.LINK_EXPIRED:
        return (
          <div className="linking-status-info expired">
            <ExclamationCircleFilled />
            {' '}
            Link expired, please resend.
            {' '}
            <a
              href="#learn-more"
              onClick={onLearnMoreClick}
              className="display-none" // hide in S22 due to lacking of requirements
            >
              Learn more
            </a>
          </div>
        );
      case CgmDeviceStatusEnum.LINK_FAILED:
        return (
          <div className="linking-status-info failed">
            <ExclamationCircleFilled />
            {' '}
            Account linking failed. Please try again.
            {' '}
            <a
              href="#learn-more"
              onClick={onLearnMoreClick}
              className="display-none" // hide in S22 due to lacking of requirements
            >
              Learn more
            </a>
          </div>
        );
      case CgmDeviceStatusEnum.LINK_SUCCESS:
        return (
          <div className="linking-status-info success">
            <CheckCircleFilled />
            {' '}
            Accounts Linked Successfully on
            {' '}
            <DisplayDateComponent
              value={dayjs(accountLinkedAt)}
              format="MMDDYY"
            />
          </div>
        );
      case CgmDeviceStatusEnum.LINK_DEAUTHED:
      default:
        return (
          <div className="linking-status-info secondary-gray">
            <InfoCircleFilled />
            {' '}
            Choose a method below to start linking
          </div>
        );
    }
  };

  return (
    <div className="linking-status-message">
      {renderMessage()}
    </div>
  );
};
