import { Button } from 'antd';
import {
  AddOnServiceEnum,
  PatientProtocolEnum,
  ProgramCategoryEnum,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { VitalEnumTypeListComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { ProgramCategoryEnumListComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { SuccessLottie } from '../../../../assets/lottie/SuccessLottie/SuccessLottie';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { AddOnServiceEnumComponent } from '../../../../enumComponent/AddOnServiceEnumComponent/AddOnServiceEnumComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { CareProtocolComponent } from '../../../patient/component/CareProtocolComponent/CareProtocolComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';

export interface EnrollmentSuccessComponentProps {
  vitals: VitalEnumType[];
  enrolledPrograms: ProgramCategoryEnum[];
  recommendedProtocol?: PatientProtocolEnum | null;
  addOnServices?: AddOnServiceEnum[];
  onOkay: () => void;
}

export const EnrollmentSuccessComponent = ({
  vitals,
  enrolledPrograms,
  recommendedProtocol,
  addOnServices,
  onOkay,
}: EnrollmentSuccessComponentProps) => {
  const { send: sendMixpanel } = useMixpanelContext();
  const hasCGMService = addOnServices?.includes(AddOnServiceEnum.CGM);

  const handleOkay = () => {
    sendMixpanel({ event: MixpanelEvents.PatientEnrollmentDone });
    onOkay();
  };

  return (
    <div className="flex fd-c jc-c ai-c w100 h100">
      <SuccessLottie />
      <h3>Good Job</h3>
      <p className="mb5">You've successfully enrolled this patient!</p>
      <div className="flex ai-c">
        <div className="b6">Care Protocol:</div>
        <div>
          {
            recommendedProtocol ? (
              <CareProtocolComponent
                recommendedProtocol={recommendedProtocol}
                hasCGMService={hasCGMService}
              />
            ) : <DisplayOrEmptyComponent />
          }
        </div>
      </div>
      <div className="flex ai-c">
        <div className="b6">Vitals to Monitor:</div>
        <div>
          <VitalEnumTypeListComponent
            value={vitals}
            splitter=", "
            type="long"
          />
        </div>
      </div>
      <div className="flex ai-c">
        <div className="b6">Additional Services:</div>
        <div>
          {
            addOnServices?.length
              ? (
                addOnServices?.map((service) => (
                  <AddOnServiceEnumComponent
                    key={service}
                    value={service}
                    type="service"
                  />
                ))
              ) : <EmptyComponent />
          }
        </div>
      </div>
      <div className="flex ai-c">
        <div className="b6">Billing Programs:</div>
        <div>
          <ProgramCategoryEnumListComponent
            value={enrolledPrograms}
            type="short"
            splitter="/"
          />
        </div>
      </div>
      <Button
        type="primary"
        onClick={handleOkay}
        className="mt40 w100px"
      >
        Got it
      </Button>
    </div>
  );
};
