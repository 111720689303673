import {
  Col, Row, Typography
} from 'antd';
import { map } from 'lodash';
import { CuffSizeEnumComponent } from '../../../../enumComponent/CuffSizeEnumComponent/CuffSizeEnumComponent';
import { DeviceTypeEnumComponent } from '../../../../enumComponent/DeviceTypeEnumComponent/DeviceTypeEnumComponent';
import {
  AssignDeviceInfo,
  DeviceAssignment,
  DeviceModelEnum,
  DeviceTypeEnum,
  LoanDevice,
  MonitorMethodEnum, PatientDevice,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { deviceModelWithCuffSize } from '../../constant';
import { useGetDeviceInfo } from '../../hook/useGetDeviceInfo';
import { DeviceEditButtonComponent } from '../DeviceEditButtonComponent/DeviceEditButtonComponent';
import { DeviceItemComponent } from './DeviceItemComponent';
import './DeviceListComponent.scss';
import { DeviceManualInputItemComponent } from './DeviceManualInputItemComponent';
import { LoanerPhoneItemComponent } from './LoanerPhoneItemComponent';
import { DeviceMonitorMethodEnumComponent } from '../../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';

const { Text } = Typography;

export interface AssignedLoanDevice extends LoanDevice {
  deviceModel: DeviceModelEnum;
}

export interface DeviceTableComponentProps {
  patientId: string;
  patientDevice?: PatientDevice;
}

export const DeviceListComponent = ({
  patientId,
  patientDevice,
}: DeviceTableComponentProps) => {
  const {
    getFullDeviceList,
    getMostRecentDeviceByType
  } = useGetDeviceInfo(patientDevice);

  return (
    <div className="device-list">
      {
        map(
          getFullDeviceList(),
          (
            device: (AssignDeviceInfo | AssignedLoanDevice),
            index: number
          ) => {
            if ((device as AssignedLoanDevice).deviceModel === DeviceModelEnum.LOAN_DEVICE) {
              return (
                <div className="card" key={index as number}>
                  <LoanerPhoneItemComponent
                    key={index as number}
                    patientId={patientId}
                    loanDevice={device as LoanDevice}
                  />
                </div>
              );
            }
            const deviceAssignment = device as DeviceAssignment;
            let aDevice = getMostRecentDeviceByType(deviceAssignment.vitalType as VitalEnumType);
            if (
              aDevice
              && aDevice.deviceMethod === MonitorMethodEnum.APP_MONITOR
              && !aDevice.cuffSize
            ) {
              aDevice = {
                ...aDevice,
                cuffSize: deviceAssignment.cuffSize ?? undefined,
              };
            }

            return (
              <div className="card" key={index as number}>
                <Row>
                  <Col span={5}>
                    <Text>
                      <DeviceTypeEnumComponent
                        value={deviceAssignment.vitalType as unknown as DeviceTypeEnum}
                      />
                    </Text>
                    <div className="text-gray-scale-2 flex">
                      <DeviceMonitorMethodEnumComponent
                        value={deviceAssignment.monitorMethod as MonitorMethodEnum}
                      />
                      <DeviceEditButtonComponent
                        patientId={patientId}
                        vitalType={deviceAssignment.vitalType as VitalEnumType}
                      />
                    </div>
                  </Col>
                  <Col span={9} className="overflow-h">
                    <Row className="flex jc-sb">
                      {
                        deviceAssignment.monitorMethod === MonitorMethodEnum.MANUALLY_INPUT
                          ? <DeviceManualInputItemComponent />
                          : <DeviceItemComponent device={aDevice} />
                      }
                    </Row>
                  </Col>
                  <Col span={10} className="flex ai-c overflow-h">
                    <div className="flex m40 ai-c gap5">
                      {/* don't show Last used or cuff size when it is manual */}
                      {
                        deviceAssignment.monitorMethod !== MonitorMethodEnum.MANUALLY_INPUT
                        && (
                          <div className="initial">
                            Last used:
                            {' '}
                            <Text type="secondary">
                              <DisplayDateComponent value={aDevice?.accessedAt} format="USA_DATE" fallbackText={<DisplayOrEmptyComponent />} />
                            </Text>
                          </div>
                        )
                      }
                      {
                        deviceAssignment.monitorMethod !== MonitorMethodEnum.MANUALLY_INPUT
                        // models with cuff size included in deviceModelWithCuffSize
                        && aDevice?.deviceModel
                        && deviceModelWithCuffSize.includes(aDevice?.deviceModel)
                        && aDevice?.cuffSize
                        && (
                          <CuffSizeEnumComponent value={aDevice.cuffSize} />
                        )
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            );
          }
        )
      }
    </div>
  );
};
