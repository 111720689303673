import { AnnouncementTypeEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface AnnouncementTypeEnumComponentProps {
  value?: AnnouncementTypeEnum;
}

export const AnnouncementTypeEnumComponent = ({
  value,
}: AnnouncementTypeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case AnnouncementTypeEnum.INCIDENT: return 'Incident';
      case AnnouncementTypeEnum.ANNOUNCEMENT: return 'Announcement';
      case AnnouncementTypeEnum.HOTFIX: return 'Hot Fix';
      case AnnouncementTypeEnum.RELEASE: return 'Release';
      case AnnouncementTypeEnum.OTHER: return 'Other';
      default: return <EmptyComponent />;
    }
  };

  return <span>{getText()}</span>;
};
