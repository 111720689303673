import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import {
  compact,
  find,
  findIndex,
  flatMap,
  forEach,
  isNaN,
  last,
  map,
  sortBy,
  uniq
} from 'lodash';
import { useRef } from 'react';
import timezone from 'dayjs/plugin/timezone';
import { useAxis } from '../../../../hooks/highcharts/useAxis';
import { useDrawYAxisWithColor } from '../../../../hooks/highcharts/useDrawYAxisWithColor';
import {
  CgmAgpPercentile,
  CgmMetricEnum,
  CgmPatientContext,
  CgmThresholdRange,
  Nullable,
} from '../../../../uc-api-sdk';
import { CGMRange } from '../../container/CGMVitalContainer/type';
import {
  CGMBounds,
  CGMChartHeight,
  CGMColorMap,
  CGMPlotLineColor,
} from '../../constant/cgmConstant';
import { useDeepCompareEffectWithInitialRender } from '../../../../hooks/useDeepCompareEffect';
import { MeasureUnitHelper } from '../../../../helpers/measurement/measureUnit';
import { useCGMChartHelper } from '../../helper/useCGMChartHelper';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

import './AGPChartComponent.scss';

dayjs.extend(timezone);
highchartsMore(Highcharts);

export interface AGPChartComponentProps {
  interval?: number;
  data?: Nullable<CgmAgpPercentile>;
  cgmPatientContext?: Nullable<CgmPatientContext>;
  timezone?: string;
  unit?: string;
}

export const AGPChartComponent = ({
  interval = 5,
  data,
  cgmPatientContext,
  timezone = cgmPatientContext?.timezone || dayjs.tz.guess(),
  unit = MeasureUnitHelper.defaultBGUnit,
}: AGPChartComponentProps) => {
  const chartContainerRef = useRef(null);
  const highchartRef = useRef<Highcharts.Chart | undefined>();
  const axisHelper = useAxis();
  const {
    makeYAxisWithColor
  } = useDrawYAxisWithColor();
  const {
    renderLabelWithUnit,
    xAxisLabelFormatter,
    addSpaceFromLastValue,
  } = useCGMChartHelper();

  const parsePatientContext = (cgmPatientContext?: Nullable<CgmPatientContext>) => {
    const patientThresholds = (
      cgmPatientContext?.thresholds as Record<CgmMetricEnum, CgmThresholdRange>
    );

    const ranges = {} as Record<CgmMetricEnum, CGMRange>;
    forEach(patientThresholds, (threshold, key) => {
      const range = {
        ...threshold,
        value: threshold.lowerBound,
        lowerBound: threshold.lowerBound,
      } as CGMRange;
      let color: string | undefined;
      switch (key as CgmMetricEnum) {
        case CgmMetricEnum.TIR:
          color = CGMColorMap.LOW;
          break;
        case CgmMetricEnum.TAR_LEVEL_1:
          color = CGMColorMap.HIGH;
          break;
        default:
      }
      ranges[key as CgmMetricEnum] = { ...range, color };
    });
    const thresholds = sortBy(map(patientThresholds, (t) => t.lowerBound || 0));
    return {
      ranges,
      thresholds,
    };
  };

  const processData = (data?: Nullable<CgmAgpPercentile>) => {
    const startTime = dayjs().startOf('day').valueOf();
    const timeOfDayMap = Array(288).fill(1).reduce((acc, _, idx) => {
      const nextTime = acc[idx - 1] ? acc[idx - 1] + (interval * 60 * 1000) : startTime;
      acc.push(nextTime);
      return acc;
    }, []);

    const p5to95 = [] as [number | undefined, number | undefined, number | undefined][];
    const p25to75 = [] as [number | undefined, number | undefined, number | undefined][];
    const p50 = [] as [number | undefined, number | undefined][];
    forEach(timeOfDayMap, (time, idx: number) => {
      p5to95.push([time, data?.p05?.[idx], data?.p95?.[idx]]);
      p25to75.push([time, data?.p25?.[idx], data?.p75?.[idx]]);
      p50.push([time, data?.p50?.[idx]]);
    });

    const values = flatMap(Object.values(data || {}));
    const dataMax = Math.max(...values);

    const lastDataPoints = [
      { value: last(compact(data?.p05)) },
      { value: last(compact(data?.p25)) },
      { value: last(compact(data?.p50)) },
      { value: last(compact(data?.p75)) },
      { value: last(compact(data?.p95)) },
    ];

    // make sure all value for ticks is unique
    forEach(lastDataPoints, (d, idx) => {
      const dup = findIndex(lastDataPoints, (ldp) => ldp.value === d.value);
      if (dup === idx) {
        return;
      }
      if (dup !== -1) {
        lastDataPoints[idx].value = (d.value || 0) + 0.00001;
      }
    });

    return {
      p5to95,
      p25to75,
      p50,
      lastP5: lastDataPoints[0].value || 0,
      lastP25: lastDataPoints[1].value || 0,
      lastP50: lastDataPoints[2].value || 0,
      lastP75: lastDataPoints[3].value || 0,
      lastP95: lastDataPoints[4].value || 0,
      dataMax,
    };
  };

  const fixPercentileTickDisplay = (
    chart: Highcharts.Chart,
  ) => {
    const axisPercentileTicks = Object.values(chart.yAxis[1]?.ticks);
    const sortedTicksByValue = sortBy(axisPercentileTicks, (t) => t.pos);
    forEach(sortedTicksByValue, (tick, idx, arr) => {
      let fontSize = Number(tick.label?.element?.style.fontSize);
      fontSize = isNaN(fontSize) ? 11 : fontSize;
      const tickLabel = tick.label as unknown as Highcharts.PointLabelObject;
      const yValue = tickLabel?.y || 0;
      const prevYValue = (
        (arr[idx - 1]?.label as unknown as Highcharts.PointLabelObject)?.y
      );
      const nextYValue = (
        (arr[idx + 1]?.label as unknown as Highcharts.PointLabelObject)?.y
      );

      if (
        (typeof nextYValue === 'number' && (yValue - fontSize <= nextYValue))
        || (typeof prevYValue === 'number' && (prevYValue - fontSize <= yValue))
      ) {
        const curMark = (tick.mark || {}) as {
          element?: SVGPathElement,
          pathArray?: [string, number, number][]
        };
        const curMarkEl = curMark?.element;
        const curLabelEl = tick.label?.element;
        const curMarkX = curMark.pathArray?.[0]?.[1] || 0;
        const curMarkY = curMark.pathArray?.[0]?.[2] || 0;
        const [smallX, largeX] = [3, 6];
        const [smallY, largeY] = [15, 26];
        const repositionMarkAndLabel = (x: number, y: number) => {
          if (curMarkX === undefined || curMarkY === undefined) {
            return;
          }
          curMarkEl?.setAttribute(
            'd',
            `M ${curMarkX} ${curMarkY} 
                      L ${curMarkX + x} ${curMarkY}
                      L ${curMarkX + x} ${curMarkY + y}
                      L ${curMarkX + 10} ${curMarkY + y}`
          );
          const alignPixel = 7; // pixel to align text to center of tick mark
          const currentStyle = curLabelEl?.getAttribute('style');
          curLabelEl?.setAttribute(
            'style',
            `${currentStyle}; top: ${curMarkY + y - alignPixel}px;`
          );
        };
        // overlapping
        switch (idx) {
          case 0: {
            repositionMarkAndLabel(smallX, largeY);
            break;
          } case 1: {
            repositionMarkAndLabel(largeX, smallY);
            break;
          } case 2:
            break;
          case 3: {
            repositionMarkAndLabel(largeX, -smallY);
            break;
          } case 4: {
            repositionMarkAndLabel(smallX, -largeY);
            break;
          } default:
        }
      }
    });
  };

  useDeepCompareEffectWithInitialRender(() => {
    const processedData = processData(data);
    const { ranges, thresholds } = parsePatientContext(cgmPatientContext);
    let dataMaxValue = Math.max(processedData.dataMax, last(thresholds) || 0);
    dataMaxValue = (
      dataMaxValue === processedData.dataMax
        ? addSpaceFromLastValue(dataMaxValue) : dataMaxValue
    );

    const parsedYAxis = axisHelper.makeRanges(ranges);
    const thresholdLines = parsedYAxis.plotLines.map((line) => ({
      ...line,
      zIndex: 5
    }));

    const commonYAxis = {
      min: 0,
      max: dataMaxValue,
      title: { text: '' },
    };

    const percentileTicks = [
      processedData.lastP5,
      processedData.lastP25,
      processedData.lastP50,
      processedData.lastP75,
      processedData.lastP95
    ] as number[];

    const finalOptions = {
      title: { text: '' },
      time: { timezone },
      tooltip: { enabled: false },
      legend: { enabled: false },
      plotOptions: { series: { clip: true } },
      chart: {
        type: 'spline',
        height: CGMChartHeight.FULL,
        events: {
          load() {
            makeYAxisWithColor([
              {
                from: 0,
                to: ranges.TIR.lowerBound || CGMBounds.LOW,
                color: ranges.TIR.color || CGMColorMap.LOW
              },
              {
                from: ranges.TIR.lowerBound || CGMBounds.LOW,
                to: ranges.TAR_LEVEL_1.lowerBound || CGMBounds.HIGH,
                color: CGMColorMap.NORMAL
              },
              {
                from: ranges.TAR_LEVEL_1.lowerBound || CGMBounds.HIGH,
                to: dataMaxValue,
                color: ranges.TAR_LEVEL_1.color || CGMColorMap.HIGH
              },
            ], this);
          },
          render() {
            const chart = this as unknown as Highcharts.Chart;
            fixPercentileTickDisplay(chart);
          }
        }
      },
      xAxis: axisHelper.makeTimeAxis({
        startOnTick: true,
        endOnTick: true,
        labels: {
          useHTML: true,
          formatter() {
            const formatter = this as unknown as Highcharts.AxisLabelsFormatterContextObject;
            return xAxisLabelFormatter(formatter.value);
          }
        },
        plotLines: [
          {
            value: dayjs().startOf('day').add(12, 'hour').valueOf(),
            color: CGMPlotLineColor.LIGHT_GRAY,
            width: 1,
          },
        ],
      }),
      yAxis: [
        {
          ...commonYAxis,
          plotLines: thresholdLines,
          tickPositions: uniq([...thresholds, dataMaxValue]),
          labels: {
            useHTML: true,
            formatter() {
              const formatter = this as unknown as Highcharts.AxisLabelsFormatterContextObject;
              if (formatter.value === 0) return '';
              const label = find(ranges, (range) => (
                !!range.color && formatter.value === range.value
              ));
              const isLastLabel = formatter.value === dataMaxValue;
              if (isLastLabel) {
                return renderLabelWithUnit(formatter.value, unit);
              }
              return (
                label
                  ? `<span style="color: ${label.color}; font-weight:500;">${label.value}</span>`
                  : `<span>${formatter.value}</span>`
              );
            }
          },
          gridLineDashStyle: 'Dash'
        }, {
          ...commonYAxis,
          opposite: true,
          startOnTick: false,
          endOnTick: false,
          tickWidth: 2,
          tickPositions: percentileTicks,
          labels: {
            useHTML: true,
            staggeringLines: 2,
            formatter() {
              const formatter = this as unknown as Highcharts.AxisLabelsFormatterContextObject;
              switch (formatter.value) {
                case processedData.lastP5: return '5%';
                case processedData.lastP25: return '25%';
                case processedData.lastP50:
                  return '<div class="agp-percentile__50">50%</div>';
                case processedData.lastP75: return '75%';
                case processedData.lastP95: return '95%';
                default: return '';
              }
            }
          },
          offset: 0,
          lineWidth: 1,
          gridLineWidth: 0,
        }
      ],
      series: [{
        name: '5-95 Percentile',
        data: processedData.p5to95,
        type: 'arearange',
        color: 'rgba(49, 119, 201, 0.14)',
        lineWidth: 0,
        marker: { enabled: false },
        enableMouseTracking: false,
        zIndex: 0,
        fillOpacity: 0.1,
        yAxis: 0 // Glucose levels (primary y-axis)
      }, {
        name: '25-75 Percentile',
        data: processedData.p25to75,
        type: 'arearange',
        color: 'rgba(49, 119, 201, 0.1)',
        lineWidth: 0,
        marker: { enabled: false },
        enableMouseTracking: false,
        zIndex: 1,
        fillOpacity: 0.2,
        yAxis: 0 // Glucose levels (primary y-axis)
      }, {
        name: 'Median',
        data: processedData.p50,
        type: 'spline',
        color: '#3177C9',
        lineWidth: 2,
        marker: { enabled: false },
        enableMouseTracking: false,
        zIndex: 2,
        yAxis: 0, // Glucose levels (primary y-axis)
      }]
    } as Highcharts.Options;

    if (chartContainerRef.current) {
      highchartRef.current = Highcharts.chart(
        chartContainerRef.current,
        finalOptions
      );
    }

    return () => {
      highchartRef.current?.destroy();
      highchartRef.current = undefined;
    };
  }, [data, cgmPatientContext]);

  return (
    <div>
      <div
        className="agp-chart"
        ref={chartContainerRef}
      />
      <div className="ta-c">
        <TextComponent color="gray2">
          AGP is a summary of glucose values from the report period,
          with median (50%) and other percentiles shown as if occurring on a single day.
        </TextComponent>
      </div>
    </div>
  );
};
