import {
  Button,
  Dropdown,
  MenuProps,
  Space,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useBoolean } from 'usehooks-ts';
import { CaretDownOutlined } from '@ant-design/icons';
import { ToolbarProps } from 'react-big-calendar';
import { CalendarTitleComponent } from '../CalendarTitleComponent/CalendarTitleComponent';
import { PrevNextButtonsComponent } from '../../../../uiComponent/PrevNextButtonsComponent/PrevNextButtonsComponent';
import { MiniCalendarComponent, MiniCalendarComponentProps } from '../MiniCalendarComponent/MiniCalendarComponent';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { dddMMMDD } from '../../../../constants/timeFormat';
import './CalendarDropdownComponent.scss';

export interface CalendarDropdownComponentProps extends Pick<ToolbarProps, 'view'>{
  date: Dayjs;
  onChange?: (date: Dayjs) => void;
  onSelectToday?: () => void;
  onSelectPrevDay?: () => void;
  onSelectNextDay?: () => void;
}

export const CalendarDropdownComponent = ({
  date,
  view = 'day',
  onChange,
  onSelectToday,
  onSelectPrevDay,
  onSelectNextDay,
}: CalendarDropdownComponentProps) => {
  const { value: open, setValue: setOpen } = useBoolean();
  const d = date.clone();

  const selectToday = () => {
    (onSelectToday || onChange)?.(dayjs());
  };

  const goToPrevDay = () => {
    (onSelectPrevDay || onChange)?.(d.clone().add(-1, 'day'));
  };

  const goToNextDay = () => {
    (onSelectNextDay || onChange)?.(d.clone().add(1, 'day'));
  };

  const handleDateChange: MiniCalendarComponentProps['onChange'] = (v) => {
    onChange?.(v);
    setOpen(false);
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <div className="day-cal-mini-cal">
          <MiniCalendarComponent
            selectDateOnMonthChange={false}
            onChange={handleDateChange}
            value={d}
          />
        </div>
      ),
      key: '1',
    },
  ];

  return (
    <div className="calendar-dropdown-component">
      <h4 className="m0">
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          open={open}
          onOpenChange={setOpen}
          overlayClassName="calendar-dropdown-overlay"
        >
          <ClickableDiv onClick={(e) => e.preventDefault()}>
            <Space>
              <CalendarTitleComponent
                view={view}
                dayFormat={dddMMMDD}
                date={d}
                showButtons={false}
              />
              <CaretDownOutlined />
            </Space>
          </ClickableDiv>
        </Dropdown>
      </h4>
      <Space>
        <PrevNextButtonsComponent
          onGoPrev={goToPrevDay}
          onGoNext={goToNextDay}
        />
        <Button
          type="text"
          onClick={selectToday}
          className="p0"
        >
          Today
        </Button>
      </Space>
    </div>
  );
};
