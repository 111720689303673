import { useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Icons } from '../../../../icons/Icons';
import { ClinicalGoalItem } from '../../../../uc-api-sdk';
import { ClipboardButtonComponent } from '../../../../uiComponent/ClipboardButtonComponent/ClipboardButtonComponent';
import { useGenerateMTPRNote } from '../../hook/useGenerateMTPRNote';
import { MTPRSeeNoteHistoryButtonComponent } from '../MTPRSeeNoteHistoryButtonComponent/MTPRSeeNoteHistoryButtonComponent';
import { useDeepCompareEffectWithInitialRender } from '../../../../hooks/useDeepCompareEffect';
import { useMonthlyMeasurementContext } from '../../../../contexts/MonthlyMeasurementContext/MonthlyMeasurementContext';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';

export interface MTPRAssistButtonsComponentProps {
  patientId: string;
  updatedClinicalGoals?: ClinicalGoalItem[];
  isMTPRDisabled?: boolean;
  setIsMTPRDisabled?: (isDisabled: boolean) => void;
  isSkipMTPRDisabled?: boolean;
}

export const MTPRAssistButtonsComponent = ({
  patientId,
  updatedClinicalGoals,
  isMTPRDisabled,
  setIsMTPRDisabled,
  isSkipMTPRDisabled = false,
}: MTPRAssistButtonsComponentProps) => {
  const {
    monthlyMeasurement,
    patientVitals,
  } = useMonthlyMeasurementContext();
  const handleGenerateNote = useGenerateMTPRNote(
    updatedClinicalGoals || [],
  );
  const [copyValue, setCopyValue] = useState('');
  const { send } = useBillableTimeInterventionComp();

  const handleClickSeeNoteHistory = () => {
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.ConductMTPR,
      detail: BillableTimeInterventionDetailEnum.OutstandingMtprSeeNoteHistory,
    });
  };

  useDeepCompareEffectWithInitialRender(() => {
    const generatedMTPRNote = handleGenerateNote(
      updatedClinicalGoals || [],
      monthlyMeasurement,
      patientVitals || [],
    );
    setCopyValue(generatedMTPRNote);
  }, [
    updatedClinicalGoals,
    monthlyMeasurement,
    patientVitals
  ]);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsMTPRDisabled?.(e.target.checked);
  };

  return (
    <div className="flex jc-sb w100 ai-c">
      <Checkbox
        onChange={handleCheckboxChange}
        className="mb5"
        checked={isMTPRDisabled}
        disabled={isSkipMTPRDisabled}
      >
        Skip MTPR in this visit
      </Checkbox>
      <div className="flex gap2">
        <MTPRSeeNoteHistoryButtonComponent
          patientId={patientId}
          onClickSeeNoteHistory={handleClickSeeNoteHistory}
          disabled={isMTPRDisabled}
        />
        <ClipboardButtonComponent
          value={copyValue}
          disabled={isMTPRDisabled}
        >
          <Icons.ClipboardPen size={22} />
        </ClipboardButtonComponent>
      </div>
    </div>
  );
};
