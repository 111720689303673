import { Space } from 'antd';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useSingleEditModeHook } from '../../../../hooks/useSingleEditModeHook/useSingleEditModeHook';
import { AnchorComponent } from '../../../../uiComponent/AnchorComponent/AnchorComponent';
import { AnchorLinkComponent } from '../../../../uiComponent/AnchorComponent/AnchorLinkComponent';
import { BillingComponent } from '../../../billing/component/BillingComponent/BillingComponent';
import { PatientInsuranceContainer } from '../../container/PatientInsuranceContainer/PatientInsuranceContainer';
import { PatientProfileDemographicsContainer } from '../../container/PatientProfileDemographicsContainer/PatientProfileDemographicsContainer';
import { PatientProfileProgramsContainer } from '../../container/PatientProfileProgramsContainer/PatientProfileProgramsContainer';
import { PatientProfileVitalsToMonitorContainer } from '../../container/PatientProfileVitalsToMonitorContainer/PatientProfileVitalsToMonitorContainer';

export const PatientProfileTabAnchors = {
  demographics: {
    label: 'Demographics',
    href: '#demographics',
  },
  programs: {
    label: 'Programs',
    href: '#billingPrograms',
  },
  vitals: {
    label: 'Vitals Monitoring',
    href: '#vitalsMonitoring',
  },
  insurance: {
    label: 'Insurance',
    href: '#insurance',
    path: 'insurance'
  },
  billingHistory: {
    label: 'Billing History',
    href: '#billingHistory',
    path: 'billingHistory'
  },
};

export enum ProfileEnumType {
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  VITALS_MONITORING = 'VITALS_MONITORING',
  INSURANCE = 'INSURANCE',
}

export const PatientProfileProfileTabComponent = () => {
  const { isEditing, handleEdit, closeEditMode } = useSingleEditModeHook<ProfileEnumType>();
  const { send: sendMixpanel } = useMixpanelContext();
  const { info } = usePatientContext();
  const { id = '', enrolledProgramService } = info || {};

  const handleEditMode = (type: ProfileEnumType) => () => {
    const getMixpanelEvent = () => {
      switch (type) {
        case ProfileEnumType.DEMOGRAPHICS:
          return MixpanelEvents.PatientDemographicsEditIcon;
        case ProfileEnumType.VITALS_MONITORING:
          return MixpanelEvents.PatientVitalsMonitoringEditIcon;
        case ProfileEnumType.INSURANCE:
          return MixpanelEvents.PatientInsuranceEditIcon;
        default:
          return MixpanelEvents.Unknown;
      }
    };

    sendMixpanel({
      event: getMixpanelEvent(),
      patientId: info?.id,
    });
    handleEdit(type)();
  };

  const demographics = () => (
    <AnchorLinkComponent
      id="demographics"
      title={PatientProfileTabAnchors.demographics.label}
      href={PatientProfileTabAnchors.demographics.href}
    >
      <PatientProfileDemographicsContainer
        isEditing={isEditing(ProfileEnumType.DEMOGRAPHICS)}
        onEdit={handleEditMode(ProfileEnumType.DEMOGRAPHICS)}
        onSubmit={closeEditMode}
        onCancel={closeEditMode}
      />
    </AnchorLinkComponent>
  );

  const programs = () => (
    <AnchorLinkComponent
      id="billingPrograms"
      title={PatientProfileTabAnchors.programs.label}
      href={PatientProfileTabAnchors.programs.href}
    >
      <PatientProfileProgramsContainer />
    </AnchorLinkComponent>
  );

  const vitalsMonitoring = () => (
    <AnchorLinkComponent
      id="vitalsMonitoring"
      title={PatientProfileTabAnchors.programs.label}
      href={PatientProfileTabAnchors.programs.href}
    >
      <PatientProfileVitalsToMonitorContainer
        isEditing={isEditing(ProfileEnumType.VITALS_MONITORING)}
        onEdit={handleEditMode(ProfileEnumType.VITALS_MONITORING)}
        onSubmit={closeEditMode}
        onCancel={closeEditMode}
      />
    </AnchorLinkComponent>
  );

  const insurance = () => (
    <AnchorLinkComponent
      id="insurance"
      title={PatientProfileTabAnchors.insurance.label}
      href={PatientProfileTabAnchors.insurance.href}
    >
      <PatientInsuranceContainer />
    </AnchorLinkComponent>
  );

  const billingHistory = () => (
    <AnchorLinkComponent
      id="billingHistory"
      title={PatientProfileTabAnchors.billingHistory.label}
      href={PatientProfileTabAnchors.billingHistory.href}
    >
      <BillingComponent patientId={id} />
    </AnchorLinkComponent>
  );

  return (
    <AnchorComponent hideAnchorLinks>
      <Space direction="vertical" size={40} className="w100">
        {(enrolledProgramService?.isEnrolled() && enrolledProgramService.id)
          ? (
            <>
              {demographics()}
              {programs()}
              {vitalsMonitoring()}
              {insurance()}
              {billingHistory()}
            </>
          ) : (
            <>
              {demographics()}
              {insurance()}
            </>
          )}
      </Space>
    </AnchorComponent>
  );
};
