import { Button, message, Popconfirm } from 'antd';
import { CgmBrandEnum, useCgmUnlink } from '../../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface CGMDeviceUnlinkAccountButtonContainerProps {
  brand: CgmBrandEnum;
  externalUserId?: string | null;
  onConfirm?: () => void;
}

export const CGMDeviceUnlinkAccountButtonContainer = ({
  brand,
  externalUserId,
  onConfirm,
}: CGMDeviceUnlinkAccountButtonContainerProps) => {
  const cgmUnlinkAccountInfo = useCgmUnlink({});
  const updatePatientCGMLinkStatus = useUpdate('CGM_LINKING_UPDATED').updateValue;

  const { isLoading } = cgmUnlinkAccountInfo;

  const handleUnlink = () => {
    if (!brand || !externalUserId) {
      message.error('Failed to unlink account.');
      return;
    }
    ApiRequestHelper.tryCatch(
      cgmUnlinkAccountInfo.send({
        params: { request: { brand, externalUserId } },
      }),
      {
        success: 'Account Unlinked Successfully!',
        error: 'Failed to unlink account.',
        onSuccess: () => {
          updatePatientCGMLinkStatus();
          onConfirm?.();
        }
      }
    );
  };

  return (
    <Popconfirm
      title={(
        <>
          <div className="b6">Are you sure you want to unlink the account?</div>
          <div className="secondary-gray">
            Once it's unlinked, Unified Care will no longer
            receive data from this account.
          </div>
        </>
      )}
      okText="Yes"
      cancelText="Cancel"
      overlayStyle={{ width: 340 }}
      okButtonProps={{
        className: 'custom-ok-button',
      }}
      onConfirm={handleUnlink}
    >
      <Button
        type="link"
        className="unlink-account-button p0"
        loading={isLoading}
        disabled={isLoading}
      >
        Unlink Account
      </Button>
    </Popconfirm>
  );
};
