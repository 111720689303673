import {
  Badge,
  Col,
  Row,
  Space,
  Tag
} from 'antd';
import { useMemo } from 'react';
import { UNIFIEDCARE_PILOT_LOGO } from '../../../assets/logoIconInfo';
import EnvConfig from '../../../configs/envConfig/envConfig';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { useMessageServicesContext } from '../../../contexts/MessageContext/MessageServicesContext';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';
import { useVisibility } from '../../../contexts/VisibilityContext/VisibilityContext';
import useChangePage, { determinePortalPlatform } from '../../../hooks/useChangePage/useChangePage';
import { Icon } from '../../../icons/Icon';
import { Icons } from '../../../icons/Icons';
import { EnvironmentTagComponent } from '../../../uiComponent/EnvironmentTagComponent/EnvironmentTagComponent';
import { UnicareCopilotComponent } from '../../../uiComponent/UnicareCopilotComponent/UnicareCopilotComponent';
import { AnnouncementButtonContainer } from '../../announcement/container/AnnouncementButtonContainer/AnnouncementButtonContainer';
import { SlideInCalendarComponent } from '../../calendar/component/SlideInCalendarComponent/SlideInCalendarComponent';
import { CallCenterStatusDropdownComponent } from '../../callCenter/components/CallCenterStatusDropdownComponent/CallCenterStatusDropdownComponent';
import { EhrOpenInTabButtonComponent } from '../../ehr/component/EhrOpenInTabButtonComponent/EhrOpenInTabButtonComponent';
import { FeedbackButtonComponent } from '../../feedback/component/FeedbackButtonComponent/FeedbackButtonComponent';
import { patientSearchOptionInHeaderRenderer } from '../../patient/component/PatientSearchSelectComponent/PatientSelectOptionInHeaderComponent';
import { PatientSearchSelectContainer } from '../../patient/container/PatientSearchSelectContainer/PatientSearchSelectContainer';
import { logoutMenuItem, settingsMenuItem } from '../../user/component/loggedInUser/LoggedInUserDropdownComponent/constant';
import { makePlatformMenuItem } from '../../user/component/loggedInUser/LoggedInUserDropdownComponent/helper';
import { LoggedInUserDropdownComponent } from '../../user/component/loggedInUser/LoggedInUserDropdownComponent/LoggedInUserDropdownComponent';
import './HeaderComponent.scss';

export interface HeaderComponentProps {
  isBillerPortal?: boolean;
}

export const HeaderComponent = ({
  isBillerPortal = false,
}: HeaderComponentProps) => {
  const changePage = useChangePage();
  const { loggedInUserHasUnread } = useMessageServicesContext();
  const { showHeaderItems } = useVisibility();
  const platformMenuItem = makePlatformMenuItem();
  const { send } = useMixpanelContext();
  const {
    isProvider
  } = useLoggedInUserFromContext();

  const handleChange = (patientId: string) => {
    changePage.goToCarePortalPatient({ patientId });
  };

  const handleMessageClick = () => {
    send({ event: MixpanelEvents.HeaderMessage });
    changePage.goToCarePortalMessages();
  };

  const userDropdownItems = useMemo(() => ([
    // ...(showOnsiteInfo ? [onSiteInfoMenuItem] : []),
    platformMenuItem,
    settingsMenuItem,
    logoutMenuItem,
  ]), [platformMenuItem, isProvider]);

  const showUnicareCopilotComponent = () => {
    // We are going to make it displayed only for some users or some clinics.
    if (EnvConfig.isUnicareCopilotEnabled) {
      return <UnicareCopilotComponent />;
    }
    return null;
  };

  const handleClickHomeIcon = () => {
    const platform = determinePortalPlatform();
    if (platform) {
      changePage.goToPlatform(platform);
    }
  };

  if (isBillerPortal) {
    return (
      <div className="care-portal-header-component">
        <Row>
          <Col span={8} className="flex ai-c">
            <Icon onClick={handleClickHomeIcon} info={UNIFIEDCARE_PILOT_LOGO} className="header-logo" />
            <div className="b7 fs20 primary-link">Billing Portal</div>
            <div className="header-preview-tag">
              <EnvironmentTagComponent />
            </div>
          </Col>
          <Col span={8} className="flex jc-c overflow-h">
            <PatientSearchSelectContainer
              onChange={handleChange}
              showClinicInFilter
              optionRenderer={patientSearchOptionInHeaderRenderer}
              value={null}
              className="header-search"
              popupClassName="header-popup"
              getAllClinics={!isProvider}
            />
          </Col>
          <Col span={8} className="flex jc-e gap5 overflow-h">
            <Space size={20}>
              <FeedbackButtonComponent />
              <LoggedInUserDropdownComponent
                items={userDropdownItems}
              />
            </Space>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className="care-portal-header-component">
      <Row>
        <Col span={8} className="flex ai-c">
          <Icon onClick={handleClickHomeIcon} info={UNIFIEDCARE_PILOT_LOGO} className="header-logo" />
          {isProvider && <Tag className="header-provider-tag" color="#E9F1FA">Provider Portal</Tag>}
          <div className="header-preview-tag">
            <EnvironmentTagComponent />
          </div>
          {
            showHeaderItems && (
              <EhrOpenInTabButtonComponent />
            )
          }
        </Col>
        <Col span={8} className="flex jc-c overflow-h">
          {
            showHeaderItems && (
              <PatientSearchSelectContainer
                onChange={handleChange}
                showClinicInFilter
                optionRenderer={patientSearchOptionInHeaderRenderer}
                value={null}
                className="header-search"
                popupClassName="header-popup"
                getAllClinics={!isProvider}
              />
            )
          }
        </Col>
        <Col span={8} className="flex jc-e gap5 overflow-h">
          <Space size={20}>
            <FeedbackButtonComponent />
            {
              showHeaderItems && (
                <>
                  <CallCenterStatusDropdownComponent />
                  {showUnicareCopilotComponent()}
                  <AnnouncementButtonContainer />
                  <Badge
                    dot={loggedInUserHasUnread}
                    className="message-red-dot"
                  >
                    <Icons.Message
                      onClick={handleMessageClick}
                      className="header-control-button"
                      asSVG
                    />
                  </Badge>
                  <SlideInCalendarComponent
                    button={(
                      <Icons.Calendar
                        className="header-control-button"
                      />
                    )}
                  />
                </>
              )
            }

            <LoggedInUserDropdownComponent
              items={userDropdownItems}
            />
          </Space>
        </Col>
      </Row>
    </div>
  );
};
