import {
  Drawer,
  DrawerProps,
  message
} from 'antd';
import { MediumDrawerWidth } from '../../../../constants/drawer';
import { CGMDeviceFormContainer } from '../../container/CGMDeviceDrawerBodyContainer/CGMDeviceFormContainer';

import './CGMDeviceDrawerComponent.scss';

export interface CGMDeviceDrawerComponentProps extends DrawerProps {
  patientId: string;
  onSubmit?: () => void;
  onClose?: () => void;
}

export const CGMDeviceDrawerComponent = ({
  patientId,
  open,
  onSubmit,
  onClose,
}: CGMDeviceDrawerComponentProps) => {
  const handleClose = () => {
    onClose?.();
  };

  const handleSubmit = () => {
    message.success('CGM device updated successfully.');
    onSubmit?.();
    handleClose();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Choose Device"
      width={MediumDrawerWidth}
      className="cgm-device-drawer"
      forceRender={false}
      maskClosable={false}
      destroyOnClose
    >
      <div className="cgm-device-drawer__body">
        <CGMDeviceFormContainer
          patientId={patientId}
          onSubmit={handleSubmit}
          onCancel={handleClose}
        />
      </div>
    </Drawer>
  );
};
