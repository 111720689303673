import { ControlLevelService } from '../../../../services/ControlLevelService';
import { PatientProtocolEnum } from '../../../../uc-api-sdk';
import { careProtocols } from '../../../enrollment/component/EnrollmentCareProtocolComponent.tsx/constant';

export interface CareProtocolComponentProps {
  controlService?: ControlLevelService;
  recommendedProtocol?: PatientProtocolEnum;
  hasCGMService?: boolean;
}

export const CareProtocolComponent = ({
  controlService,
  recommendedProtocol,
  hasCGMService,
}: CareProtocolComponentProps) => {
  const protocol = recommendedProtocol ?? controlService?.getControlLevelInfo()?.protocol;

  const renderProtocolName = () => {
    const careProtocol = careProtocols[protocol as keyof typeof careProtocols];
    const title = careProtocol?.title || 'UNKNOWN Care Protocol';
    if (hasCGMService) {
      return (
        <>
          <span>{title}</span>
          {' '}
          <span>+ CGM Service</span>
        </>
      );
    }
    return title;
  };

  if (!protocol) {
    return null;
  }

  return (
    <span>
      {renderProtocolName()}
    </span>
  );
};
