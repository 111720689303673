import { Radio } from 'antd';
import {
  compact, find, map
} from 'lodash';
import { InputType } from '../../../Input/types';
import { CgmBrandEnum, CgmModelEnum, CgmSupportedDevice } from '../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import './CGMDeviceModelSelectorComponent.scss';
import { CGMDeviceModelEnumComponent } from '../../../../enumComponent/CGMDeviceModelEnumComponent/CGMDeviceModelEnumComponent';
import { CGMCurrentSelectionComponent } from '../CGMDeviceFormItemComponent/CGMCurrentSelectionComponent';

interface CGMDeviceModelSelectorComponentProps extends InputType<CgmModelEnum> {
  brand: CgmBrandEnum;
  data: CgmSupportedDevice[];
  defaultValue?: CgmModelEnum | null;
  showCurrentSelection?: boolean;
}

export const CGMDeviceModelSelectorComponent = ({
  brand,
  data,
  defaultValue,
  showCurrentSelection,
  value,
  onChange,
}: CGMDeviceModelSelectorComponentProps) => {
  const options = useDeepCompareMemo(() => {
    const models = find(data, { brand })?.devices || [];
    const options = map(models, (option) => (
      option.model
        ? {
          label: (
            <CGMCurrentSelectionComponent
              isCurrentSelection={showCurrentSelection && defaultValue === option.model}
            >
              <CGMDeviceModelEnumComponent value={option.model} />
            </CGMCurrentSelectionComponent>
          ),
          value: option.model,
        } : null
    ));
    return compact(options);
  }, [data, brand, defaultValue]);

  return (
    <Radio.Group
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      options={options}
    />
  );
};
