import { useEffect } from 'react';
import { useMedicationContext } from '../../../contexts/MedicationContext/MedicationContext';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';
import { useMpTrackingHelper } from '../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { AddEditMedicationFormComponent } from '../component/AddEditMedicationFormComponent/AddEditMedicationFormComponent';
import { MedicationType } from '../type/type';

export interface EditMedicationContainerProps {
  initialValues: MedicationType;
  onSubmit: (v: MedicationType) => void;
  patientId?: string;
  autoEnableReminder?: boolean;
}

export const EditMedicationContainer = ({
  initialValues,
  onSubmit,
  patientId,
  autoEnableReminder
}: EditMedicationContainerProps) => {
  const { onUpdate } = useMedicationContext();
  const { send: sendMixpanel } = useMixpanelContext();
  const { send } = useBillableTimeInterventionComp();
  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.EditMedicationStart,
    eventEnd: MixpanelEvents.EditMedicationEnd,
    patientId,
  });

  useEffect(() => {
    startEvent();
  }, []);

  const handleSubmit = (v: MedicationType) => {
    const updatedV = {
      ...v,
      medicationType: v.medicationName?.type || undefined,
      name: v.medicationName?.displayName || '',
      dictionaryId: v.medicationName?.id || undefined,
    };
    sendMixpanel({ event: MixpanelEvents.EditMedication });
    send({
      patientId: initialValues.memberId || patientId,
      component: BillableTimeInterventionComponentEnum.Transcribing,
      detail:
        BillableTimeInterventionDetailEnum.TranscribingVisitTranscribeMedicationListEditMedication,
    });
    if (initialValues.id) {
      onUpdate?.(updatedV, initialValues.id);
    }
    onSubmit?.(updatedV);
    endEvent();
  };

  return (
    <AddEditMedicationFormComponent
      onSubmit={handleSubmit}
      initialValues={initialValues}
      submitText="Save"
      autoEnableReminder={autoEnableReminder}
    />
  );
};
