import { Table, TableProps, Typography } from 'antd';
import { CgmMetricEnum } from '../../../../uc-api-sdk';
import './CgmGoalTooltipComponent.scss';

const { Text } = Typography;

interface CgmGoalTooltipDataType {
  category: React.ReactNode;
  tbrLevel2: React.ReactNode;
  tbrLevel1: React.ReactNode;
  tir: React.ReactNode;
  tarLevel1: React.ReactNode;
  tarLevel2: React.ReactNode;
}

// CGM goal default threshold by category
const THRESHOLD_CONFIG = {
  [CgmMetricEnum.TBR_LEVEL_2]: { label: 'TBR', sign: '< ', range: '54 mg/dL' },
  [CgmMetricEnum.TBR_LEVEL_1]: { label: 'TBR', sign: '< ', range: '70 mg/dL' },
  [CgmMetricEnum.TIR]: { label: 'TIR', sign: '', range: { DM2: '70-180 mg/dL', preDM: '70-140 mg/dL' } },
  [CgmMetricEnum.TAR_LEVEL_1]: { label: 'TAR', sign: '> ', range: { DM2: '180 mg/dL', preDM: '140 mg/dL' } },
  [CgmMetricEnum.TAR_LEVEL_2]: { label: 'TAR', sign: '> ', range: { DM2: '250 mg/dL', preDM: '200 mg/dL' } },
};

const renderThresholdRange = (metricLevel: CgmMetricEnum, isDM2 = true) => {
  const config = THRESHOLD_CONFIG[metricLevel as keyof typeof THRESHOLD_CONFIG];
  let thresholdRange: string;
  if (typeof config.range === 'string') {
    thresholdRange = config.range;
  } else {
    thresholdRange = isDM2 ? config.range.DM2 : config.range.preDM;
  }

  return (
    <>
      {config.label}
      <br />
      <span>
        {config.sign}
        {thresholdRange}
      </span>
    </>
  );
};

const generateData = (isDM2: boolean): CgmGoalTooltipDataType[] => [
  {
    category: isDM2 ? 'DM1/DM2' : 'Pre-DM',
    tbrLevel2: renderThresholdRange(CgmMetricEnum.TBR_LEVEL_2),
    tbrLevel1: renderThresholdRange(CgmMetricEnum.TBR_LEVEL_1),
    tir: renderThresholdRange(CgmMetricEnum.TIR, isDM2),
    tarLevel1: renderThresholdRange(CgmMetricEnum.TAR_LEVEL_1, isDM2),
    tarLevel2: renderThresholdRange(CgmMetricEnum.TAR_LEVEL_2, isDM2),
  },
  {
    category: '1',
    tbrLevel2: <span>&lt; 1%</span>,
    tbrLevel1: <span>&lt; 4%</span>,
    tir: <span>&gt; 70%</span>,
    tarLevel1: <span>&lt; 25%</span>,
    tarLevel2: <span>&lt; 5%</span>,
  },
];

const columns: TableProps<CgmGoalTooltipDataType>['columns'] = [
  {
    title: 'RowHead',
    dataIndex: 'category',
    render: (text, record, index) => ({
      children: text,
      props: index === 0 ? { rowSpan: 2 } : { colSpan: 0 },
    }),
    width: 80,
  },
  { title: 'tbrLevel2', dataIndex: 'tbrLevel2', width: 78 },
  { title: 'tbrLevel1', dataIndex: 'tbrLevel1', width: 78 },
  { title: 'tir', dataIndex: 'tir', width: 90 },
  { title: 'tarLevel1', dataIndex: 'tarLevel1', width: 85 },
  { title: 'tarLevel2', dataIndex: 'tarLevel2', width: 85 },
];

const renderTable = (isDM2: boolean) => (
  <Table<CgmGoalTooltipDataType>
    columns={columns}
    dataSource={generateData(isDM2)}
    pagination={false}
    showHeader={false}
    rowClassName="CgmGoalTooltipComponent__custom-row"
    onRow={() => ({ className: 'CgmGoalTooltipComponent__range-box' })}
    className="CgmGoalTooltipComponent__custom-table"
  />
);

export const CgmGoalTooltipComponent = () => (
  <div className="CgmGoalTooltipComponent">
    <Text className="CgmGoalTooltipComponent__title">CGM Default Target Ranges</Text>
    <div className="text-gray-scale-2 fs12 my12">
      Time In Range defaults for DM1/DM2 are set according to the ATTD (Advanced Technologies and
      <br />
      Treatments for Diabetes) consensus conference. While CGM-based glycemic targets should be
      <br />
      personalized to individual needs, the group consensus values were determined in the context of
      <br />
      reducing complications and hypoglycemic episodes.
    </div>
    {renderTable(true)}
    <div className="text-gray-scale-2 fs12 my12">
      For pre-DM patients, there is no accepted standard CGM-based glycemic targets, so finger stick
      <br />
      targets will be substituted.
    </div>
    {renderTable(false)}
  </div>
);
