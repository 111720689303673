import {
  compact,
  find,
  flatten,
  forEach,
  isEmpty,
  map
} from 'lodash';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  DeviceAssignment,
  MonitoringVital,
  useClinicGet,
  usePatientUpdatePatientDevice,
  usePatientUpsertPatientEnrolledProgram
} from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { DeviceHelper } from '../../../device/helper';
import { useUnassignDevices } from '../../../device/hook/useUnassignDevices';
import { InterventionBaseProps } from '../../../intervention/type';
import { PatientCommonCardComponent } from '../../component/PatientCardComponent/PatientCommonCardComponent';
import { PatientProfileVitalsToMonitorComponent, ProgramsSubmitValues } from '../../component/PatientProfileVitalsToMonitorComponent/PatientProfileVitalsToMonitorComponent';

export interface PatientProfileVitalsToMonitorContainerProps extends InterventionBaseProps {
  onError?: () => void;
}

export const PatientProfileVitalsToMonitorContainer = ({
  isEditing,
  onEdit,
  onSubmit,
  onCancel,
  onError,
}: PatientProfileVitalsToMonitorContainerProps) => {
  const { info } = usePatientContext();
  const { patientInfo, enrolledProgramService } = info || {};
  const patientId = patientInfo?.id || '';
  const clinicInfo = useClinicGet({
    params: { id: patientInfo?.clinicId || '' },
    options: { sendOnMount: !!patientInfo?.clinicId },
  });
  const enrolledProgramUpdateInfo = usePatientUpsertPatientEnrolledProgram({});
  const deviceUpdateInfo = usePatientUpdatePatientDevice({});
  const { unassignDevices } = useUnassignDevices();
  const updateDeviceHook = useUpdate('DEVICE_MONITOR_UPDATED');
  const updateProgramHook = useUpdate('PROGRAM_UPDATED');

  const isLoading = !clinicInfo.data?.data === undefined || clinicInfo.isLoading;

  const onSubmitSuccess = () => {
    info?.refetch(['enrolledProgramRefetch']);
    onSubmit?.();
  };

  const handleOnSubmit = async (values: ProgramsSubmitValues) => {
    if (!values || isEmpty(values)) {
      onSubmit?.();
      return;
    }

    const vitalList = [] as MonitoringVital[];
    const deviceList = [] as DeviceAssignment[];
    forEach(values.vitals, (v) => {
      const { type, deviceMonitor } = v;
      if (!type) return;
      vitalList.push({ type });
      if (deviceMonitor) {
        const {
          connectedDevice,
          cuffSize,
          monitorMethod,
        } = deviceMonitor;
        deviceList.push({
          vitalType: type,
          monitorMethod,
          cuffSize: (!connectedDevice && cuffSize) || undefined,
          devices: (
            connectedDevice
              ? [{
                deviceId: connectedDevice.deviceId,
                deviceModel: connectedDevice.deviceModel,
                deviceMethod: DeviceHelper.getMethodByModel(connectedDevice.deviceModel),
                cuffSize,
              }]
              : undefined
          ),
        });
      }
    });

    await ApiRequestHelper.tryCatch(
      enrolledProgramUpdateInfo.send({
        params: {
          memberId: patientId,
          enrolledProgram: {
            ...values,
            vitals: vitalList,
          },
        }
      }),
      {
        success: '',
        error: 'Failed to update enrolled program.',
        onError,
        onSuccess: (res) => {
          // map schedule to created vitals; otherwise, vitals will be overwritten
          // TODO: [t] Ethan needs to improve this
          const newVitalList = res?.data?.vitals;
          const vitalWithSchedules = map(newVitalList, (v) => {
            const vital = find(values.vitals, { type: v.type });
            if (vital && vital.schedule) {
              return { ...v, ...vital.schedule };
            }
            return v;
          });
          updateProgramHook.updateValue();
          ApiRequestHelper.tryCatch(
            enrolledProgramUpdateInfo.send({
              params: {
                memberId: patientId,
                enrolledProgram: {
                  vitals: vitalWithSchedules,
                  isEditVitals: true,
                },
              }
            }),
            {
              success: 'Update vital schedules successfully.',
              error: 'Failed to update vital schedules.',
              onError,
            }
          );
        }
      }
    );

    // Remove devices by vitals, always remove before any device update
    const deviceIds = compact(flatten(Object.values(values.removeDevicesFor || {})));
    if (!isEmpty(deviceIds)) {
      await unassignDevices(patientId, deviceIds);
    }

    // Update device monitor
    if (isEmpty(deviceList)) {
      onSubmitSuccess();
      return;
    }

    ApiRequestHelper.tryCatch(
      deviceUpdateInfo.send({
        params: {
          memberId: patientInfo?.id as string,
          updateRequest: { deviceList },
        }
      }),
      {
        success: 'Updated device monitor successfully.',
        error: 'Failed to update device monitor.',
        onError,
        onSuccess: () => {
          updateDeviceHook.updateValue();
          onSubmitSuccess();
        }
      }
    );
  };

  return (
    <PatientCommonCardComponent
      isEditing={isEditing}
      onEdit={onEdit}
      updateInfo={enrolledProgramService?.enrolledProgram}
      title="Vitals to Monitor"
      content={(
        <PatientProfileVitalsToMonitorComponent
          patientInfo={info}
          enrolledProgramService={enrolledProgramService}
          isEditing={false}
        />
      )}
      formContent={(
        <LoadingOverlayComponent
          isLoading={isLoading}
          showSkeleton
        >
          <PatientProfileVitalsToMonitorComponent
            patientInfo={info}
            enrolledProgramService={enrolledProgramService}
            isEditing
            onSubmit={handleOnSubmit}
            onCancel={onCancel}
            isLoading={enrolledProgramUpdateInfo.isLoading}
          />
        </LoadingOverlayComponent>
      )}
    />
  );
};
