import { useMemo } from 'react';

interface CalculatedValue {
  valueInPercent: number
}
export interface RawValues {
  value: number,
  total: number | undefined,
}
export type DisplayPercentageComponentProps = {
  decimal?: number,
} & (CalculatedValue | RawValues);
export const DisplayPercentageComponent = (
  props: DisplayPercentageComponentProps,
) => {
  const {
    decimal = 0,
  } = props;

  const percent = useMemo(() => {
    if (typeof (props as CalculatedValue).valueInPercent === 'number') {
      return (props as CalculatedValue).valueInPercent;
    }
    const {
      value,
      total: _total,
    } = props as RawValues;
    const total = _total || 1;
    return (value / total) * 100;
  }, [props]);

  return (
    <span className="display-percentage">
      {percent > 0 ? percent.toFixed(decimal) : 0}
      %
    </span>
  );
};
