import { Button } from 'antd';
import React from 'react';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';

const EnrollmentDrawerComponent = React.lazy(() => import('../../../enrollment/component/EnrollmentDrawerComponent/EnrollmentDrawerComponent'));
export interface UpdateEnrollmentButtonComponentProps {
  patientId: string;
}

export const UpdateEnrollmentButtonComponent = ({
  patientId
}: UpdateEnrollmentButtonComponentProps) => {
  const { isOpen, open, close } = useOpen();

  return (
    <div className="UpdateEnrollmentButtonComponent">
      <Button onClick={open} type="link">Update Enrollment</Button>
      <EnrollmentDrawerComponent
        patientId={patientId}
        onClose={close}
        isOpen={isOpen}
      />
    </div>
  );
};
