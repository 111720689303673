import { Card, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CgmMetricEnum } from '../../../../uc-api-sdk';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import './CgmVitalSummaryComponent.scss';
import { CgmEmptySummaryComponent } from './CgmEmptySummaryComponent';

export interface CgmStatisticsComponentProps {
  activeTime?: number;
  avgCgm?: number;
  gv?: number;
  gmi?: number;
}

export const CgmStatisticsComponent = ({
  activeTime,
  avgCgm,
  gv,
  gmi
}: CgmStatisticsComponentProps) => {
  const isDataValid = [activeTime, avgCgm, gv, gmi].some(value => value !== undefined);

  const cgmStatisticsTitle = () => (
    <div className="flex">
      <strong>Glucose Statistics</strong>
      <Tooltip
        placement="topRight"
        overlayStyle={{ minWidth: '580px' }}
        title={(
          <div>
            <div>
              <span className="bold">GV (Glycemic Variability)</span>
              {' '}
              refers to fluctuations in blood glucose levels and is defined
              as the ratio of the standard deviation to the average glucose
              level (in %), also termed as % coefficient of variation.
            </div>
            <div>
              <span className="bold">GMI (Glucose Management Indicator)</span>
              {' '}
              is a calculated metric that reflects the average glucose values
              from a CGM device translated into an HbA1C equivalent value (in %).
            </div>
          </div>
        )}
      >
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );

  const cgmStatisticsItems = () => (
    <div className="text-gray-scale-2">
      {[
        'Active Time (≥70%)',
        'Average Glucose (≤154 mg/dL)',
        'Glycemic Variability (≤36%)',
        'GMI/Estimated A1C (≤7%)'
      ].map((item) => (
        <div key={item}>{item}</div>
      ))}
    </div>
  );

  const displayMetricValue = (metricName: CgmMetricEnum, value?: number) => {
    if (value !== undefined) {
      return (
        <>
          {Math.floor(value * 10) / 10}
          {metricName === CgmMetricEnum.AVERAGE_GLUCOSE ? ' mg/dL' : '%'}
        </>
      );
    }
    return <EmptyComponent />;
  };

  const cgmStatisticsContent = () => (
    <div className="flex">
      <div className="mr32">
        {cgmStatisticsItems()}
      </div>
      <div className="bold">
        <div>
          {displayMetricValue(CgmMetricEnum.CGM_ACTIVE_MINUTES, activeTime)}
        </div>
        <div>
          {displayMetricValue(CgmMetricEnum.AVERAGE_GLUCOSE, avgCgm)}
        </div>
        <div>
          {displayMetricValue(CgmMetricEnum.GV, gv)}
        </div>
        <div>
          {displayMetricValue(CgmMetricEnum.GMI, gmi)}
        </div>
      </div>
    </div>
  );

  return (
    <Card className="CgmStatisticsComponent mb0">
      <div className="flex">
        <div className="w-mc mr12">
          {cgmStatisticsTitle()}
        </div>
        <div>
          {!isDataValid ? (<CgmEmptySummaryComponent />) : cgmStatisticsContent()}
        </div>
      </div>
    </Card>
  );
};
