import { useEffect, useState } from 'react';
import {
  first,
  last,
  partialRight
} from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import { FromDateToDateDayjs } from '../../type';
import { CGMDatePickerContainer } from '../CGMDatePickerContainer/CGMDatePickerContainer';
import { PluralComponent } from '../../../../uiComponent/PluralComponent/PluralComponent';
import { CGMChartContainer } from '../CGMChartContainer/CGMChartContainer';
import {
  CgmPercentGoal,
  useCgmGetPatientAgp,
  useCgmMobileGetPatientCgmReading
} from '../../../../uc-api-sdk';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { useDeepCompareEffectWithInitialRender } from '../../../../hooks/useDeepCompareEffect';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { CgmVitalSummaryComponent } from '../../component/CgmVitalSummaryComponent/CgmVitalSummaryComponent';

import './CGMVitalContainer.scss';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';

dayjs.extend(dayjsTimezone);

export interface CGMVitalContainerProps {
  patientId: string;
  percentGoals?: CgmPercentGoal[];
}

export const CGMVitalContainer = ({
  patientId,
  percentGoals
}: CGMVitalContainerProps) => {
  const [fromToDate, setFromToDate] = useState<FromDateToDateDayjs>();
  const [availableDays, setAvailableDays] = useState<number>();
  const cgmReadingInfo = useCgmMobileGetPatientCgmReading({
    options: { sendOnMount: false }
  });
  const agpInfo = useCgmGetPatientAgp({
    options: { sendOnMount: false }
  });

  const fetchCGMReadingAvailableDates = (
    fromDate?: Dayjs,
    toDate?: Dayjs,
    includeSummary?: boolean,
  ) => {
    const startTime = fromDate?.startOf('day')?.utc()?.toISOString();
    const endTime = toDate ? dayjs(toDate).endOf('day').toISOString() : undefined;
    cgmReadingInfo.send({
      params: {
        request: {
          patientId,
          includeAvailableDates: true,
          includeRawData: false,
          includeSummary,
          startTime: startTime || dayjs(endTime).subtract(1, 'year').startOf('day').toISOString(),
          endTime: endTime || dayjs().endOf('day').toISOString(),
        }
      }
    });
  };

  const handleSetFromToDate = (
    v?: FromDateToDateDayjs,
    includeSummary?: boolean,
  ) => {
    setFromToDate(v);
    fetchCGMReadingAvailableDates(v?.fromDate, v?.toDate, includeSummary);
  };

  useDeepCompareEffectWithInitialRender(() => {
    const availableDates = cgmReadingInfo?.data?.data?.availableDates;
    const timezone = cgmReadingInfo?.data?.data?.context?.patientContext?.timezone ?? undefined;
    const availableDays = availableDates?.length || 0;
    setAvailableDays(availableDays);
    if (!fromToDate && availableDates) {
      // only for initial render

      // get last date with data
      const firstInAvailable = first(availableDates);
      const lastInAvailable = last(availableDates);
      const lastAvailable = (
        dayjs(firstInAvailable).isBefore(dayjs(lastInAvailable))
          ? lastInAvailable
          : firstInAvailable
      );

      // auto-select last 14 days from last date with data
      const firstAvailable = dayjs().startOf('day').subtract(13, 'day').toISOString();

      handleSetFromToDate({
        fromDate: dayjs(firstAvailable).tz(timezone).startOf('day'),
        toDate: dayjs(lastAvailable)?.tz(timezone).endOf('day'),
      }, true);
    }
  }, [
    fromToDate,
    cgmReadingInfo?.data?.data?.availableDates
  ]);

  useEffect(() => {
    ApiRequestHelper.tryCatch(agpInfo.send({
      params: {
        request: { patientId }
      }
    }), {
      success: '',
      error: '',
      onSuccess: (res) => {
        const {
          startTime,
          endTime,
          isApplicable,
          context,
        } = res?.data || {};
        if (isApplicable && startTime && endTime) {
          const timezone = context?.patientContext?.timezone ?? undefined;
          handleSetFromToDate({
            fromDate: TimezoneService.calcDateTimeDayjs(startTime, timezone),
            toDate: TimezoneService.calcDateTimeDayjs(endTime, timezone),
          }, true);
        } else {
          // no agp data, check with cgmReadingInfo to get last 14 days
          handleSetFromToDate();
        }
      },
      onError: () => {
        // no agp data, check with cgmReadingInfo to get last 14 days
        handleSetFromToDate();
      }
    });
  }, []);

  return (
    <div className="mb60 cgm-vital-container">
      <FetchComponent
        info={cgmReadingInfo}
        alwaysShowChildren
      >
        <div className="cgm-vital-container__header">
          <div className="flex ai-s jc-sb">
            <PatientCommonCardTitleComponent>
              Continuous Glucose Monitoring
            </PatientCommonCardTitleComponent>
            <CGMDatePickerContainer
              patientId={patientId}
              value={fromToDate ?? undefined}
              onChange={partialRight(handleSetFromToDate, true)}
            />
          </div>
          <div className="flex ai-c">
            Based on your selection,
            {
              availableDays !== undefined
                ? (
                  <PluralComponent
                    value={availableDays || 0}
                    unit="day"
                  />
                ) : <EmptyComponent />
            }
            of CGM data are available
          </div>
        </div>
        <LoadingOverlayComponent isLoading={!fromToDate}>
          <CgmVitalSummaryComponent
            metricData={cgmReadingInfo?.data?.data?.metrics || undefined}
            percentGoals={percentGoals}
          />
          <CGMChartContainer
            patientId={patientId}
            startDate={fromToDate?.fromDate || ''}
            endDate={fromToDate?.toDate || ''}
          />
        </LoadingOverlayComponent>
      </FetchComponent>
    </div>
  );
};
