import { Button, Col, Row } from 'antd';
import { NestedFormControlProvider } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { ProgramCategoryEnum } from '../../../../uc-api-sdk';
import { PatientProfileProgramEnrollmentComponent } from '../PatientProfileProgramEnrollmentComponent/PatientProfileProgramEnrollmentComponent';
import { PatientProfileProgramsItemComponent } from '../PatientProfileProgramsItemComponent/PatientProfileProgramsItemComponent';

import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { PauseServiceDrawerComponent } from '../../../../uiComponent/PauseServiceDrawerComponent/PauseServiceDrawerComponent';
import { ReviewAndResignConsentComponent } from '../../../consentForm/component/ReviewAndResignConsentComponent/ReviewAndResignConsentComponent';
import { DischargeModalButtonComponent } from '../../../discharge/component/DischargeModalButtonComponent/DischargeModalButtonComponent';
import { UnableToEngageModalContainer } from '../UnableToEngageModalButtonComponent/UnableToEngageModalButtonComponent';
import { UpdateEnrollmentButtonComponent } from '../UpdateEnrollmentButtonComponent/UpdateEnrollmentButtonComponent';

export interface PatientProfileBillingProgramsComponentProps {
  patientInfo?: PatientInfo;
  enrolledProgramService?: EnrolledProgramService;
  isEditing?: boolean;
  patientClinicProgramParticipation: ProgramCategoryEnum[];
}

export const PatientProfileBillingProgramsComponent = ({
  patientInfo,
  enrolledProgramService,
  patientClinicProgramParticipation,
  isEditing,
}: PatientProfileBillingProgramsComponentProps) => {
  useUpdateListener('PATIENT_DISCHARGED', () => {
    patientInfo?.refetch();
  });
  if (!patientInfo?.patientInfo || enrolledProgramService === undefined) return null;

  const handleOnResignConsent = () => {
    patientInfo?.refetch(['enrolledProgramRefetch']);
  };

  return (
    <NestedFormControlProvider>
      <Row>
        <Col span={12}>
          <PatientProfileProgramsItemComponent
            title="Program Enrollment"
            className="flex fd-c profile-program-enrollment"
            isEditing={isEditing}
            formContent={(
              <PatientProfileProgramEnrollmentComponent
                enrolledProgramService={enrolledProgramService}
                patientClinicProgramParticipation={patientClinicProgramParticipation}
              />
            )}
          >
            <PatientProfileProgramEnrollmentComponent
              enrolledProgramService={enrolledProgramService}
              patientClinicProgramParticipation={patientClinicProgramParticipation}
            />
          </PatientProfileProgramsItemComponent>
        </Col>
        <Col span={12} className="flex jc-e">
          <ReviewAndResignConsentComponent
            patientInfo={patientInfo}
            onResign={handleOnResignConsent}
            showSentModal={false}
          >
            <Button>View Signed Consent Form</Button>
          </ReviewAndResignConsentComponent>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={12} className="flex gap0 jc-e ai-e f-w profile-program-buttons">
          <UpdateEnrollmentButtonComponent patientId={patientInfo.id} />
          <PauseServiceDrawerComponent
            memberId={patientInfo.id}
          />
          <UnableToEngageModalContainer patientId={patientInfo.id} />
          <DischargeModalButtonComponent
            patientInfo={patientInfo}
          />
        </Col>
      </Row>
    </NestedFormControlProvider>
  );
};
