export const CGMColorMap = {
  LOW: 'var(--secondary-red, #DA6453)',
  NORMAL: '#000',
  HIGH: 'var(--secondary-orange, #EF973B)',
};

export const CGMPlotLineColor = {
  LIGHT_GRAY: '#B5B9BE',
  DARK_GRAY: '#6B7178',
  BLUE_SELECTION: '#107EFF',
};

export const CGMPlotBandColor = {
  LIGHT_GRAY: 'rgba(217, 217, 217, 0.2)',
};

export const CGM_DAYS_LIMIT = 14;

export const CGM_VALUE_LIMIT = 250; // mg/dL

export const CGM_DAY_RANGE_LIMIT = 90;

export const CGMBounds = {
  LOW: 70,
  HIGH: 180,
};

export const CGMChartHeight = {
  COMPACT: 200,
  FULL: 400
};
