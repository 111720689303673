import { map } from 'lodash';
import { CARE_PROTOCOL_BACKGROUND_ICON } from '../../../../assets/commonIconInfo';
import { Icon } from '../../../../icons/Icon';
import { PatientProtocolEnum } from '../../../../uc-api-sdk';
import CardComponent from '../../../../uiComponent/CardComponent/CardComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { CareProtocolComponent } from '../../../patient/component/CareProtocolComponent/CareProtocolComponent';
import { careProtocols, careProtocolWithCGM } from './constant';
import './EnrollmentCareProtocolComponent.scss';

export interface CareProtocolComponentProps {
  recommendedProtocol: PatientProtocolEnum;
  hasCGMService?: boolean;
}

export const EnrollmentCareProtocolComponent = ({
  recommendedProtocol,
  hasCGMService,
}: CareProtocolComponentProps) => {
  const careProtocol = careProtocols[recommendedProtocol as keyof typeof careProtocols];

  const renderContent = () => {
    const content = !hasCGMService ? careProtocol.content : careProtocolWithCGM.content;
    return content
      ? map(content, (v, i) => (<li key={i}>{v}</li>))
      : 'Missing Protocol Content!';
  };

  return (
    <div className="care-protocol mt5 mb20">
      <TextComponent
        className="mb5"
        size="large"
        bold
      >
        Care Protocol
      </TextComponent>
      <CardComponent hovered className="care-protocol-card">
        <div className="care-protocol-content">
          <h5 className="care-protocol-title">
            <CareProtocolComponent
              recommendedProtocol={recommendedProtocol}
              hasCGMService={hasCGMService}
            />
          </h5>
          <ul className="care-protocol-list">
            {renderContent()}
          </ul>
          <Icon info={CARE_PROTOCOL_BACKGROUND_ICON} className="care-protocol-icon" />
        </div>
      </CardComponent>
    </div>
  );
};
