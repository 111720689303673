import {
  first,
  groupBy,
  last
} from 'lodash';
import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import dayjs from 'dayjs';
import { useCgmMobileGetPatientCgmReading } from '../../../../uc-api-sdk';
import { DatePeriodEnum } from '../../../../uiComponent/DateDropdownAndRangeComponent/DatePeriodDropdownComponent';
import { DateRangeSelectComponent, DateRangeSelectComponentProps } from '../../../../uiComponent/DateRangeSelectComponent/DateRangeSelectComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { FromDateToDateDayjs } from '../../type';
import { USA_DATE } from '../../../../constants/timeFormat';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { CGM_DAY_RANGE_LIMIT } from '../../constant/cgmConstant';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';

import './CGMDatePickerContainer.scss';

const CGM_DATE_PICKER_POPUP_CLASS = 'cgm-date-picker__popup';

export interface CGMDatePickerContainerProps {
  patientId: string;
  value?: FromDateToDateDayjs;
  onChange?: (v: FromDateToDateDayjs) => void;
}

export const CGMDatePickerContainer = ({
  patientId,
  value,
  onChange,
}: CGMDatePickerContainerProps) => {
  const {
    isOpen,
    setValue: setIsOpen,
  } = useOpen();
  const [datesInView, setDatesInView] = useState<string[]>();
  const [reachLimit, setReachLimit] = useState(false);
  const mutationObsever = useRef<MutationObserver | undefined>();
  const cgmReadingInfo = useCgmMobileGetPatientCgmReading({
    params: {
      request: {
        patientId,
        includeAvailableDates: true,
        startTime: datesInView?.[0],
        endTime: datesInView?.[1],
      }
    },
    options: {
      sendOnMount: !!patientId && datesInView?.length === 2,
      // cache when end date is before today
      cache: dayjs(datesInView?.[1]).endOf('day').isBefore(dayjs().endOf('day'), 'day'),
    }
  });
  const availableDates = useMemo(() => (
    groupBy(cgmReadingInfo?.data?.data?.availableDates, (v) => dayjs(v).format(USA_DATE))
  ), [cgmReadingInfo?.data?.data?.availableDates]);

  const handleSetFromToDate = useDebounce((v: FromDateToDateDayjs) => {
    setReachLimit(false);
    const inLimit = v.toDate.diff(v.fromDate, 'days') <= CGM_DAY_RANGE_LIMIT;
    if (!inLimit) {
      setReachLimit(true);
      setIsOpen(true);
      return;
    }
    onChange?.(v);
  }, 250);

  const handleOnDateRangeChange = useDebounce(() => {
    const pickerCells = document.querySelectorAll(`.${CGM_DATE_PICKER_POPUP_CLASS} .ant-picker-cell`);
    const firstDate = first(pickerCells)?.getAttribute('title');
    const lastDate = last(pickerCells)?.getAttribute('title');
    setDatesInView([
      dayjs(firstDate).startOf('day').utc().toISOString(),
      dayjs(lastDate).endOf('day').utc().toISOString(),
    ]);
  }, 250);

  const startObserver = useDebounce(() => {
    try {
      if (mutationObsever.current) {
        return;
      }
      handleOnDateRangeChange();
      mutationObsever.current = new MutationObserver(handleOnDateRangeChange);
      const pickerPopup = document.querySelector(`.${CGM_DATE_PICKER_POPUP_CLASS} .ant-picker-panels`);
      if (pickerPopup) {
        mutationObsever.current.observe(
          pickerPopup,
          { subtree: true, attributes: true, attributeFilter: ['title'] }
        );
      }
    } catch (e) {
      console.error(e);
      // ignore
    }
  });

  const cleanObserver = () => {
    mutationObsever.current?.disconnect();
    mutationObsever.current = undefined;
  };

  const renderDate = useCallback<NonNullable<DateRangeSelectComponentProps['dateRender']>>((current) => {
    const hasData = current.format(USA_DATE) in availableDates;
    return (
      <div className="ant-picker-cell-inner">
        {current.date()}
        {hasData && <div className="date-has-cgm-data-dot" />}
      </div>
    );
  }, [availableDates]);

  const renderExtraFooter = useCallback<NonNullable<DateRangeSelectComponentProps['renderExtraFooter']>>(() => (
    <div className="flex fd-c ai-c jc-sb">
      <div className="flex w100 ai-c jc-e">
        <div className="date-has-cgm-data-dot" />
        CGM Data Available
      </div>
      <div className="w100">
        {
          reachLimit
          && (
            <AlertCardComponent
              type="warning"
              message="You can't select more than 90 days."
            />
          )
        }
      </div>
    </div>
  ), [reachLimit]);

  useEffect(() => {
    if (!isOpen) {
      cleanObserver();
    }
    return cleanObserver;
  }, [isOpen]);

  return (
    <LoadingOverlayComponent isLoading={!value}>
      {
        value && (
          <DateRangeSelectComponent
            value={value}
            onChange={handleSetFromToDate}
            styleType="full"
            dropdownOptions={[
              DatePeriodEnum.SevenDays,
              DatePeriodEnum.FourteenDays,
              DatePeriodEnum.ThirtyDays,
              DatePeriodEnum.NinetyDays,
            ]}
            open={isOpen}
            onOpenChange={(isOpen) => {
              if (isOpen) startObserver();
              setIsOpen(isOpen);
            }}
            dateRender={renderDate}
            popupClassName={CGM_DATE_PICKER_POPUP_CLASS}
            renderExtraFooter={renderExtraFooter}
          />
        )
      }
    </LoadingOverlayComponent>
  );
};
